import {  SendOutlined } from '@ant-design/icons'
import { Button, Image, Input, List } from 'antd'
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { api } from 'src/services';

export default function MessageBox({
    dataSource,
    setData,
    setDataMessage,
    chatId,
    userId,
    cusor,
    loadMoreMessage,
    isScrollDown
}) {
    const messagesEndRef = useRef(null);
    const [content, setContent] = useState("");
    /* FETCH FUNCTION */
    const sendMessage = async(chat_id:any, _content:any)=> {
        if (!content.trim()) return;
        try {
            setContent("");
            const { data: _messages }:any = await api.sendMessage(chat_id, _content);
            
            // update lại tin nhắn mới
            setDataMessage((prev:any)=> [_messages[0],...prev]);
        } catch (error) {
            console.error(error);
        }
    }

    /* HELPER FUNCTION */
    const isAuthor = ({user}:any) =>{
        return (user._id === userId);
    }

    const getLastTimeSeen = ({createdAt}) =>{
        let timeAgo = moment(createdAt).fromNow();
        return timeAgo;
    }

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSubmit = (event:any) => {
        event.preventDefault();
        if (content.trim()) {
            sendMessage(chatId, content);
        }
    };

    const handleKeyPress = (event:any) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSubmit(event);
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [dataSource]);

    return (
        <div className='message-box-wrapper'>
            <div className="message-list-wrapper">
                <List
                    dataSource={dataSource}
                    renderItem={(item: any, index :number) => {
                        return (index !== dataSource.length - 1) ?
                            <div className='message-item'>
                                { index === 0 && isScrollDown ? <div ref={messagesEndRef} /> : '' }
                                <List.Item 
                                    style={{ width: "100%" }}
                                    className={isAuthor(item) ? 'author-message' : ''}
                                >
                                    <div className='chat-box'>
                                        <div className="chat-content">
                                            { 
                                                item.text ? 
                                                item.text :
                                                <Image width={200} src={item.image} />
                                            }
                                        </div>
                                        <div className="chat-send-time">
                                            <span style={{fontSize:12}}>{getLastTimeSeen(item)}</span>
                                        </div>
                                    </div>
                                </List.Item>
                            </div> :
                            <>
                                <div className='message-item'>
                                    { index === 0 && isScrollDown ? <div ref={messagesEndRef} /> : '' }
                                    <List.Item 
                                        style={{ width: "100%" }}
                                        className={isAuthor(item) ? 'author-message' : ''}
                                    >
                                        <div className='chat-box'>
                                            <div className="chat-content">
                                                { 
                                                    item.text ? 
                                                    item.text :
                                                    <Image width={200} src={item.image} />
                                                }
                                            </div>
                                            <div className="chat-send-time">
                                                <span style={{fontSize:12}}>{getLastTimeSeen(item)}</span>
                                            </div>
                                        </div>
                                    </List.Item>
                                </div>
                                { cusor ? <div className='message-item'>
                                    { index === 0 ? <div ref={messagesEndRef} /> : '' }
                                    <List.Item 
                                        style={{ width: "100%" }}
                                        className='load-more-message'
                                    >
                                        <Button type="primary" onClick={()=>loadMoreMessage(chatId)}>Thêm</Button>
                                    </List.Item>
                                </div> : <></>}
                               
                            </>

                    }}
                />
            </div>
            <div className="message-input">
                <form onSubmit={handleSubmit}>
                    <textarea 
                        placeholder="Nhập..." 
                        value={content}
                        onChange={(e)=> setContent(e.target.value)} 
                        onKeyPress={handleKeyPress}
                    />
                </form>
                <div className="button-send">
                    <Button 
                        type="primary" 
                        shape="circle" 
                        icon={<SendOutlined />} 
                        onClick={()=>sendMessage(chatId, content)}
                    />
                </div>
            </div>
        </div>
    )
}
