import { List, Tag, Card, Skeleton } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { utils } from "src/utils";

function ServiceResultModal({ number, patientId }) {
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState({});

  useEffect(() => {
    fetchData();
  }, [number, patientId]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getServiceResultPatient(patientId);
      if (rPatitent) {
        setData(rPatitent);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="admin-table OrderTest">
      <div className="flex-fill">
        <div>
          <Card
            title="Kết quả"
            size="small"
            loading={loading}
            style={{ marginBottom: 16 }}
          >
            <Skeleton loading={loading}>
              {data.length > 0 ? (
                <List
                  dataSource={data}
                  renderItem={(item: any) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          "Xét nghiệm iMEDICAL" +
                          " - " +
                          utils.formatDate(
                            (
                              _.last(item?.created_at) as {
                                date: Date;
                              }
                            )?.date
                          )
                        }
                        description={
                          <div>
                            <Tag>
                              <div className="tag-subclinical">
                                <a href={item.url} target="_blank">
                                  {item.url}
                                </a>
                              </div>
                            </Tag>
                          </div>
                        }
                      />
                      {item?.doctor?.name}
                    </List.Item>
                  )}
                />
              ) : (
                "Không tìm thấy thông tin"
              )}
            </Skeleton>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default ServiceResultModal;
