export type ActionTypes = {
  type: "SAVE_OPTION" | "DELETE_OPTION";
  payload?: any;
};

const saveOption = (user: any) => {
  return {
    type: "SAVE_OPTION",
    payload: user,
  };
};

const deleteOption = () => {
  return {
    type: "DELETE_OPTION",
  };
};

const item = {
  saveOption,
  deleteOption,
};

export default item;
