import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getNotifications<T>(
  cursor: number | null = 0,
  perPage: number,
  category: string | null = ''
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/notifications`,
        {
          cursor,
          per_page : perPage,
          category
        }
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function readNotification<T>(id: string): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/notifications/${id}/read`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getNotifications,
  readNotification,
};

export default data;
