import { BreadcrumbComponent } from "src/components";
import Statistical from "../Statistical";
import News from "../News";
import NewsList from "src/components/News/NewsList";
import { NavLink } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";

// import { Card, List } from "antd";

// const data = [
//   {
//     title: "Title 1",
//   },
//   {
//     title: "Title 2",
//   },
//   {
//     title: "Title 3",
//   },
//   {
//     title: "Title 4",
//   },
// ];

const breadcrumbItems = [{ path: "/", name: "Dashboard" }];

export default function Dashboard() {
  return (
    <div>
      <BreadcrumbComponent items={breadcrumbItems} />
      {/* <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card title={item.title}>Card content</Card>
          </List.Item>
        )}
      /> */}
      <Statistical />
      <NewsList />
    </div>
  );
}
