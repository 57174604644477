import { Button, Col, Empty, List, Modal, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  FormatDate,
  IconDateCreate,
  IconViewDetail,
  ServiceResultModal,
} from "src/components";
import { api } from "src/services";
import { SubclinicalAdd, SubclinicalDetail } from "src/views";
import { images } from "src/assets";

const ListSubclinical = ({ patient_id }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData]: any = useState([]);
  const [isModalOpenDetail, setIsModalOpenDetail] = useState(false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
  const [subclinical_id, setSubclinical_id] = useState(false);
  const [number, setNumber] = useState(0);
  const limiter = 9;
  const [isModalOpenServiceResultDetail, setIsModalOpenServiceResultDetail] =
    useState(false);

  const fetchData = async () => {
    if (!patient_id) {
      setData([]);
      return;
    }
    try {
      setLoading(true);
      const { data: rData }: any = await api.getAllSubclinicals(patient_id);
      
      if (rData) {
        setData(rData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patient_id]);

  const handleNameClick = async () => {
    if (number === limiter) {
      setNumber(number - 9);
    } else {
      setNumber(number + 1);
    }
  };

  return (
    <>
      <List
        locale={{
          emptyText: <Empty description="Không tìm thấy cận lâm sàng" />,
        }}
        loading={loading}
        className="list-sub-clinic bg-white"
        size="small"
        header={
          <>
            {/* <Row>
              <Col span={23}>
                <span className="font-bold">Cận lâm sàng</span>
              </Col>
              <Col span={1}>
                {patient_id ? (
                  <IconAdd
                    title="Thêm cận lâm sàng"
                    onClick={() => setIsModalOpenCreate(true)}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row> */}
            <span className="font-bold">Cận lâm sàng</span>
            {
              patient_id &&
              <Button 
                style={{ height: '100%' }}
                size="small" 
                type="primary" 
                onClick={() => setIsModalOpenCreate(true)}
              >
                Thêm mới kết quả
              </Button>
            }
          </>
        }
        bordered
        dataSource={data}
        renderItem={(item: any) => (
          <List.Item
            key={item?.id}
            onClick={() => {
              if (item.is_imed) {
                handleNameClick();
                setIsModalOpenServiceResultDetail(true);
              } else {
                handleNameClick();
                setIsModalOpenDetail(true);
                setSubclinical_id(item.id);
              }
            }}
            actions={[
              item?.updated_at ? (
                <IconDateCreate
                  created_at={<FormatDate date={item?.updated_at} />}
                />
              ) : null,
              <IconViewDetail
                onClick={() => {
                  if (item.is_imed) {
                    handleNameClick();
                    setIsModalOpenServiceResultDetail(true);
                  } else {
                    handleNameClick();
                    setIsModalOpenDetail(true);
                    setSubclinical_id(item.id);
                  }
                }}
              />,
            ]}
          >
            <List.Item.Meta
              title={
                <Row>
                  <Col
                  // span={18}
                  >
                    <div className="patient-booking-name">
                      <div className="avatar-container">
                        {item.icon_img ? (
                          <img
                            alt={item.icon_img}
                            className="avatar avatar-subcilinical"
                            src={item.icon_img}
                          />
                        ) : (
                          <img
                            className="avatar avatar-subcilinical"
                            src={images.subclinical.xn1}
                            alt={images.subclinical.xn1}
                          />
                        )}
                        <span className="size-xs">{item.name}</span>
                      </div>
                    </div>
                  </Col>
                  {/* <Col span={6}>
                    {item?.updated_at && (
                      <IconDateCreate
                        created_at={<FormatDate date={item?.updated_at} />}
                      />
                    )}
                  </Col> */}
                </Row>
              }
            />
          </List.Item>
        )}
      />
      <Modal
        title="Xem chi tiết kết quả dịch vụ"
        centered
        open={isModalOpenServiceResultDetail}
        footer={null}
        width={900}
        className="modal-scroll"
        onCancel={() => {
          setIsModalOpenServiceResultDetail(false);
        }}
      >
        <ServiceResultModal number={number} patientId={patient_id} />
      </Modal>
      <Modal
        title="Chi tiết cận lâm sàng"
        centered
        open={isModalOpenDetail}
        onOk={() => {
          setIsModalOpenDetail(false);
        }}
        onCancel={() => setIsModalOpenDetail(false)}
        footer={null}
        width={900}
        className="modal-scroll"
      >
        <Spin spinning={loading}>
          <SubclinicalDetail
            number={number}
            subclinicalsId={subclinical_id}
            patient_id={patient_id}
          />
        </Spin>
      </Modal>

      <Modal
        title="Thêm cận lâm sàng"
        centered
        open={isModalOpenCreate}
        onOk={() => {
          setIsModalOpenCreate(false);
          fetchData();
        }}
        onCancel={() => {
          setIsModalOpenCreate(false);
        }}
        footer={null}
        width={1300}
        className="modal-scroll"
      >
        <SubclinicalAdd
          id={patient_id}
          fetchData={fetchData}
          onSuccess={() => {
            setIsModalOpenCreate(false);
          }}
        />
      </Modal>
    </>
  );
};

export default ListSubclinical;
