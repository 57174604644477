import { MessageOutlined } from '@ant-design/icons'
import {  Avatar, Button, Drawer } from 'antd'
import ListChat from './ListChat';
import { useEffect, useState } from 'react';
import MessageBox from './MessageBox';
import { api } from 'src/services';
import { useDispatch, useSelector } from 'react-redux';
import Echo from 'laravel-echo';
import ioClient from 'socket.io-client';
import { allActions } from 'src/redux';
import _ from "lodash";
export default function Chat({
    openDrawer,
    setOpenDrawer
}) {
    /*  INIT VARIABLE  */
    const [data, setData] = useState([]);
    const [adminChat, setAdminChat] = useState(null);
    const [chatId, setChatId] = useState(null);
    const [dataMessage, setDataMessage] = useState([]);
    const [titleBox, setTitleBox] = useState('Tin nhắn');
    const [loading, setLoading] = useState(false);
    const [isChat, setIsChat] = useState(false);
    const [cusor, setCusor] = useState('');
    const [hasUnread, setHasUnread] = useState(false);
    const [isScrollDown, setIsScrollDown] = useState(true);
    const [nextPageSize, setNextPageSize] = useState(12);
    const [isLoadMore, setIsLoadmore] = useState(false);
    const [searchChatList, setSearchChatList] = useState('');
    const dispatch = useDispatch();

    let chatperPage =  12;
    /* INIT REDUX */
    // get userData from redux (global data)
    let userId = null;
    const userData = useSelector((state:any) => state.user);

    const userActiveMessage = useSelector((state:any) => state.option);

    if(userData) {
        userId  = userData?.id;
    }

    /* HELPER FUNCTION */
    const openMessageBox = async (chat_id : number, title: any) =>{
        setIsScrollDown(true);
        setLoading(true);
        setChatId(chat_id);
        updateRead(chat_id);
        await fetchMessages(chat_id);
        setLoading(false);
        setIsChat(true);
        setTitleBox(title);
    }

    const closeBoxChat = () => {
        setCusor('');
        if(isChat){
            fetchChatList();
            fetchAdminChat();
            setIsChat(false);
            setChatId(null);
            setDataMessage([]);
            setTitleBox("Tin nhắn");
        } else {
            setOpenDrawer(false)
        }
    }

    /* FETCH FUNCTION */
    const fetchChatList = async () => {
        try {
            setLoading(true);
            const {data : rData, meta: _meta}: any = await api.getChatList( chatperPage, searchChatList);
            if (rData && rData.length) {
                setHasUnread(rData.some((item:any) => item?.is_read === "0"));
                setData(rData);

                if(_meta.total > nextPageSize){
                    setNextPageSize(chatperPage + 12);
                }
                if(_meta.current_page !== _meta.last_page){
                    setIsLoadmore(true);
                }else{
                    setIsLoadmore(false);
                }
            }
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    const fetchAdminChat = async () => {
        try {
            const { data }: any = await api.getChat(0);

            if (data) {
                setAdminChat(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const updateRead = async(chat_id:any)=>{
        try {
            const res = await api.updateChat(chat_id, { is_read: true});
        } catch (error) {
            console.error(error);
        }
    }

    const fetchMessages = async (chatId:any) => {
        try {
            const { data :_data , meta: _meta}: any = await api.getMessages(chatId, cusor);
            if (_data) {
                setDataMessage(_data);
            }
            if(_meta?.next_cursor){
                setCusor(_meta?.next_cursor);
            }else{
                setCusor('');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const loadMoreMessage = async (chatId:any) => {
        try {
            const { data : _data, meta: _meta}: any = await api.getMessages(chatId, cusor);
            setIsScrollDown(false);
            if (_data) {
                setDataMessage(prev=> [...prev , ..._data]);
            }
            if(_meta?.next_cursor){
                setCusor(_meta?.next_cursor);
            }else{
                setCusor('');
            }
        } catch (error) {
            console.error(error);
        }
    };


    const getUserTargetChat = ({ members }) =>{
        if(!members) return {};

        if(members[0]._id === userId){
            return members[1];
        }else {
            return members[0];
        }
    }

    const getTargetChatAvatar = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.avatar;
    }

    const getTargetChatName = (data:any) =>{
        const user:any = getUserTargetChat(data);
        return user.name;
    }

    const openBoxChat = async(data:any) =>{
        try {
            setOpenDrawer(true);
            const { data : _data }: any = await api.getChat(data.id);
            const res = await openMessageBox(_data.id, 
                <div className='header-message'> 
                    <Avatar src={getTargetChatAvatar(_data)}/> 
                    {getTargetChatName(_data)}
                </div>
            );
        } catch (error) {
            console.error(error);
        }
    }

    /* EFFECT RELOAD RENDER ON CHANGE */
    useEffect(() => {
        fetchChatList();
        fetchAdminChat();
    }, []);

    useEffect( ()=>{
        fetchChatList();
    }, [searchChatList]);

    useEffect(() => {
        const isSocketOn =  process.env.REACT_APP_SOCKET_ON == "true" || false;
        if(isSocketOn){
            const echo = new Echo({
                broadcaster: 'socket.io',
                host: `${process.env.REACT_APP_SOKCET}`,
                transports: [`polling`], // polling : use in mobile,  websocket
                client: ioClient,
            });
            echo.channel(`room.${userId}`).listen(
                `.Dev\\Chat\\Events\\MessagePosted`,
                ({ messages: _messages, chat : _chat }: any) => {
                    // console.log("__append__", isChat);
                    // Nếu là đang chat
                    if(isChat){
                        updateRead(chatId);
                        setDataMessage((prev:any)=> [_messages[0] , ...prev]);
                    }
                    // update ngoài danh sách chat
                    setData(prev => {
                        const index = prev.findIndex(el => el.id == _chat.id);
                        // Tìm không ra thì xoá cái cuối, append lên đầu
                        if (index !== -1) {
                          return [
                            ...prev.slice(0, index),
                            {
                              ...prev[index],
                              lastMessage: _messages[0],
                              is_read: 0,
                            },
                            ...prev.slice(index + 1),
                          ];
                        } else {
                          return [
                            {
                              ..._chat,
                              is_read: 0,
                            },
                            ...prev,
                          ];
                        }
                    });
                },
            );
    
            return () => {
                echo.disconnect();
            };
        }
    }, [isChat]);

    useEffect(()=>{
        if(userActiveMessage){
            openBoxChat(userActiveMessage);
            dispatch(allActions.option.deleteOption());
        }
    },[userActiveMessage]);

    async function loadMoreListChat(){
        chatperPage = nextPageSize;
        await fetchChatList();
    }

    /* RENDER FUNCTION */
    const messageBox = (isOn:boolean) =>{
        if(isOn){
            return (<MessageBox 
                dataSource={dataMessage}
                setData={setData}
                setDataMessage={setDataMessage}
                chatId={chatId}
                userId={userId}
                cusor={cusor}
                loadMoreMessage={loadMoreMessage}
                isScrollDown={isScrollDown}
            />);
        } 
    }

    return (
        <div className='imedical-chat-wrapper'>
            <Drawer
                title={titleBox}
                width={500}
                onClose={() => closeBoxChat()}
                open={openDrawer}
                className='drawer-chat'
            >
                <div className='full-height' style={{display : !isChat ? 'none' : ''}}>
                    { messageBox(isChat) }
                </div>
                <div className='full-height' style={{display : isChat ? 'none' : ''}}>
                    <ListChat
                        dataSource={data}
                        adminChat={adminChat}
                        loading={loading}
                        userId={userId}
                        openChat={openMessageBox}
                        isLoadMore={isLoadMore}
                        loadMoreListChat={loadMoreListChat}
                        searchChatList={searchChatList}
                        setSearchChatList={setSearchChatList}
                    />
                    <div className="load-more-chat">
                    </div>
                </div>
            </Drawer>

            <Button className='message-btn' onClick={()=>setOpenDrawer(true)} type="primary" shape="circle">
                <MessageOutlined />
                <div className="dot" style={{display: hasUnread ? 'inline-block' : 'none'}}></div>
            </Button>
        </div>
    )
}