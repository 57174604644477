import { ArrowRightOutlined } from "@ant-design/icons";
import { Card, List } from "antd";
import Meta from "antd/lib/card/Meta";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { api } from "src/services";

const NewsDetail = (props) => {
  /* INIT STATE */
  const [news_data, setNewsData] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const postId = props.match.params.id;

  const getCategoryName = ({categories}) =>{
    return categories[0]?.name ?? "";
  }

  const fetchData = async () =>{
    try {
      setLoading(true);
      const { data: rData }:any = await api.getPostDetail(postId);
      console.log(rData);
      
      setNewsData(rData);
      setData(rData?.posts_related ?? []);
    } catch (error) {
      console.error(error)
    }
    setLoading(false);
  }
  /* EFFECT CHANGE RENDER */

  useEffect(()=>{
    fetchData();
  },[props.match.params.id])

  return (
    <>
      <Card loading={loading} className="news-detail-wrapper">
        {news_data ? 
          <div className="container-custom">
            <div className="title">{news_data.name}</div>
            <div dangerouslySetInnerHTML={{__html: news_data.content}} />
            <div className="created">
              <div className="created-categories-name">{ getCategoryName(news_data) }</div>
              <div className="created-days">{news_data.created}</div>
            </div>
          </div>
          : "" }
      </Card>

      <div className="list-type-news">
        <h1>Tin tức liên quan</h1>
        <List
          className="ant-news-list"
          dataSource={data}
          loading={loading}
          renderItem={(item:any, index:any)=>(
            <NavLink to={`/news/${item.id}`} key={index}>
              <Card key={index} cover={<img alt={item.name} src={item.image} />}>
                <div className="fs-18 IBMPlexSans-Bold">{item.name}</div>
              </Card>
            </NavLink>
          )}
        />
        <div className="news-link">
          <NavLink to="/news">
            Xem tất cả
            <ArrowRightOutlined />
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default NewsDetail;
