import {
  Button,
  DatePicker,
  List,
  Avatar,
  Space,
  ConfigProvider,
  Spin,
  message,
} from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { images } from "src/assets/";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import viVN from "antd/lib/locale/vi_VN";
import "moment/locale/vi";
import { useSelector } from "react-redux";
import { UserProperty } from "src/types";
import _ from "lodash";

dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD";
const dateFormat1 = "DD-MM-YYYY";

export default function Statistical() {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;

  const maxEndDate = moment(date.toISOString().slice(0, 10));
  const isDisabledEndDate = (date) => {
    return date.isAfter(maxEndDate, "day");
  };

  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const user = useSelector<Storage, UserProperty>((state) => state.user);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const startUTC = moment(startDate, dateFormat1).format(dateFormat);
    const endUTC = moment(endDate, dateFormat1).format(dateFormat);
    try {
      setLoading(true);
      const { data: rData }: any = await api.getStatistical(
        user.id,
        startUTC,
        endUTC
      );
      if (rData) {
        const newData = _.map(rData, (item) => ({
          img: item.name,
          name: item.name,
          value: parseInt(item.value),
          key: item.id,
        }));
        setData(newData);
        setLoading(false);
      } else {
        message.error("Lỗi");
      }
    } catch (error) {
      message.error(error);
    }
  };

  const handleSubmit = async () => {
    const startUTC = moment(startDate, dateFormat1).format(dateFormat);
    const endUTC = moment(endDate, dateFormat1).format(dateFormat);

    setLoading(true);
    const { data: rData }: any = await api.getStatistical(
      user.id,
      startUTC,
      endUTC
    );
    if (rData) {
      const newData = _.map(rData, (item, key) => ({
        img: item.name,
        name: item.name,
        value: parseInt(item.value),
        key: item.id,
      }));

      setData(newData);
      setLoading(false);
    }
  };

  const handleDateChange = (dates: any, dateStrings: any) => {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    if (!dateStrings[0]) {
      message.error("Xin hãy chọn ngày tháng năm");
      setLoadingButton(true);
    } else {
      setLoadingButton(false);
    }
    //
  };

  const moneyType = [
    "drug_total",
    "drug_profit",
    "examination_total",
    "order_patient_tests_total",
  ];

  const defaultRow = [
    {
      img: images.statistical.benhnhandatkham,
      name: "Số lượng bệnh nhân đặt khám",
      value: 0,
    },
    {
      img: images.statistical.benhnhandakham,
      name: "Số lượng bệnh nhân đã khám",
      value: 0,
    },
    {
      img: images.statistical.benhnhanduocgioithieu,
      name: "Số lượng bệnh nhân được Giới thiệu",
      value: 0,
    },
    {
      img: images.statistical.benhnhangioithieudi,
      name: "Số lượng bệnh nhân Giới thiệu đi",
      value: 0,
    },
    {
      img: images.statistical.benhnhanhuykham,
      name: "Số lượng bệnh nhân Hủy khám",
      value: 0,
    },
    {
      img: images.statistical.doanhsothuoc,
      name: "Doanh số Thuốc",
      value: 0,
    },
    {
      img: images.statistical.loinhuanthuoc,
      name: "Lợi nhuận Thuốc",
      value: 0,
    },
    {
      img: images.statistical.doanhsokham,
      name: "Doanh số Khám bệnh",
      value: 0,
    },
    {
      img: images.statistical.doanhsoxetnghiem,
      name: "Doanh số Xét nghiệm",
      value: 0,
    },
  ];

  const row = defaultRow.map((item, index) => {
    const dataItem = Object.values(data).find((d) => d.name === item.name);
    return {
      ...item,
      value: dataItem ? moneyType.includes(dataItem.key || "") ? parseFloat(dataItem.value).toLocaleString("vi-VN") + " đ" : parseInt(dataItem.value) : item.value,
    };
  });

  moment.locale("vi");

  return (
    <div className="statistical">
      <Spin spinning={loading}>
        <h1>Thống kê</h1>
        <div className="list-statistical">
          <div className="rangerpicker">
            <Space direction="vertical" size={12}>
              <ConfigProvider locale={viVN}>
                <RangePicker
                  defaultValue={[
                    moment(startDate, dateFormat1),
                    moment(endDate, dateFormat1),
                  ]}
                  format={dateFormat1}
                  onChange={handleDateChange}
                  disabledDate={isDisabledEndDate}
                />
              </ConfigProvider>
            </Space>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={loadingButton}
            >
              Load
            </Button>
          </div>
          <div>
            <List
              grid={{
                gutter: 0,
                column: 2,
              }}
              dataSource={row}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{
                      // width: 300,
                      height: 60,
                      borderRadius: 10,
                      margin: "0 20px",
                      background: "#effaff",
                      boxShadow: "0px 4px 4px 0px #67daff33",
                    }}
                    avatar={<Avatar src={item.img} alt="{item.name}" />}
                    title={item.name}
                    description={item.value.toLocaleString("vi-VN")}
                  />
                </List.Item>
              )}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
