import { Button, Card, Col, Form, Row, Skeleton, Upload, message } from "antd";
import { useEffect, useRef, useState, useCallback } from "react";
import { api } from "src/services";
import { PlusOutlined } from "@ant-design/icons";
import { CategoriesType } from "src/types";
import TextArea from "antd/lib/input/TextArea";
import { CardDisease, CardSave, CardTypeTest } from "src/components";
import _ from "lodash";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

function ClinicalNotesAdd(props) {
  const { id, fetchData, onSuccess, setFileUpload, fileUpload, number } = props;
  const [testCategories, setTestCategories] = useState<CategoriesType[]>([]);
  const [diseaseCategories, setDisease] = useState([]);
  const formRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [loadingDisease, setLoadingDisease] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [onlyTest, setOnlyTest] = useState<boolean>(false);
  const [searchQueryOrderByTest, setSearchQueryOrderByTest] = useState("");
  const [searchQueryDisease, setSearchQueryDisease] = useState("");
  const [selectedDiseases, setSelectedDiseases] = useState([]);
  const [form] = Form.useForm();
  const [checkFileUpload, setCheckFileUpload] = useState(0);
  const [typeTextErrorMessage, setTypeTextErrorMessage] = useState("");
  const [selectedTests, setSelectedTests] = useState([]);
  const [loadingOrderTest, setLoadingOrderTest] = useState(false);
  const minCurrentScroll = 30;
  const [currentScroll, setCurrentScroll] = useState(minCurrentScroll);
  const [total, setTotal] = useState(0);

  const handleCheckFileUpload = () => {
    setFileUpload([]);
    setCheckFileUpload(0);
  };

  useEffect(() => {
    fetchDiseaseCategories();
  }, [searchQueryDisease, number, currentScroll]);

  useEffect(() => {
    fetchOrderTestCategories();
    if (number) {
      setLoadingUpload(true);
      setTimeout(() => {
        setLoadingUpload(false);
      }, 2000);
    }
  }, [searchQueryOrderByTest, number]);

  const fetchOrderTestCategories = async () => {
    try {
      setLoadingOrderTest(true);
      const { data: rData }: { data: CategoriesType[] } =
        await api.getTestCategories(null, searchQueryOrderByTest);
      if (rData) {
        setTestCategories(rData);
      } else {
        setTestCategories([]);
        setLoadingOrderTest(false);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      // setLoading(false);
      setLoadingOrderTest(false);
    }
  };

  const fetchDiseaseCategories = async () => {
    try {
      setLoadingDisease(true);
      const { data: rData, meta }: any = await api.getTestAllDisease(
        `${currentScroll}`,
        searchQueryDisease
      );
      if (rData) {
        setDisease(rData);
        setTotal(meta.total);
      } else {
        setDisease([]);
        setCurrentScroll(minCurrentScroll);
        setTotal(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
      setLoadingDisease(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onBeforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setFileUpload([]);
      message.error("You can only upload JPG/PNG files!");
      fetchDiseaseCategories();
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      fetchDiseaseCategories();
      return;
    }
    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };

    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url) => {
      fileObject.url = url;
    });

    return false;
  };

  const handleCheckboxChange = (checkedValues) => {
    const isChecked = selectedTests.includes(checkedValues.id);
    const newSelected = isChecked
      ? selectedTests.filter(
          (selectedTest) => selectedTest !== checkedValues.id
        )
      : [...selectedTests, checkedValues.id];

    setSelectedTests(newSelected);
    setTypeTextErrorMessage("");
  };

  const handlePopupScroll = useCallback(
    (e) => {
      const node = e.target;
      const bottom =
        node.scrollHeight - Math.ceil(node.scrollTop) === node.clientHeight;
      if (bottom) {
        setLoadingDisease(true);
        setCurrentScroll((prev) => prev + minCurrentScroll);
      }
    },
    [currentScroll]
  );

  const handleSelectedDiseases = useCallback((value) => {
    setSelectedDiseases(value);
  }, []);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const debouncedSearch = debounce(setSearchQueryDisease, 300);

  const handleSearch = _.debounce(function (e: any) {
    setSearchQueryOrderByTest(e.target.value);
  }, 300);

  const handleSubmit = async (values) => {
    let formData = new FormData();
    formData.append("patient_id", id);
    if (!onlyTest) {
      formData.append("examination_results", values.examination_results);
      formData.append("note", values.note);
      selectedDiseases.forEach((type_disease, index) => {
        formData.append(`type_disease[${index}]`, type_disease);
      });
      if (selectedTests.length > 0) {
        selectedTests.forEach((type_test, index) => {
          formData.append(`type_test[${index}]`, type_test);
        });
      } else {
        return message.error("Cần ít nhất 1 yêu cầu xét nghiệm");
      }
      setFileUpload([]);
    } else {
      values.examination_results = "";
      values.note = "";
      setSelectedTests([]);
      setSelectedDiseases([]);
      if (checkFileUpload > 0) {
        fileUpload.forEach((img, index) => {
          formData.append(`images[${index}]`, img.file);
        });
      } else {
        setLoading(false);
        return message.error("Hãy thêm ít nhất 1 ảnh");
      }
    }
    try {
      setLoading(true);
      setLoadingOrderTest(true);
      setLoadingDisease(true);
      const response: any = await api.submitClinicalNotes(formData);
      if (response.error) {
        message.error(response.message);
        setCurrentScroll(minCurrentScroll);
      } else {
        onSuccess();
        form.resetFields();
        formRef.current.resetFields();
        message.success(response.message);
        setCurrentScroll(minCurrentScroll);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSelectedTests([]);
      setSelectedDiseases([]);
      values.examination_results = "";
      values.note = "";
      fetchData();
      setSelectedTests([]);
      setLoadingOrderTest(false);
      setLoadingDisease(false);
      setLoading(false);
      handleCheckFileUpload();
      setCurrentScroll(minCurrentScroll);
    }
  };

  const onRemove = (file) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    setFileUpload(updatedFiles);
  };

  return (
    <div className="admin-table">
      <div className="flex-fill">
        <Form
          ref={formRef}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          className="form"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card
                size="small"
                style={{
                  marginBottom: 16,
                  height: "unset",
                  // padding: "1rem 1rem 0 1rem",
                }}
                // loading={loading}
              >
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Button
                    className={!onlyTest ? "button-active" : ""}
                    type="default"
                    size="large"
                    onClick={() => {
                      setOnlyTest(false);
                      handleCheckFileUpload();
                      setSearchQueryDisease("");
                      setSearchQueryOrderByTest("");
                      setCurrentScroll(minCurrentScroll);
                      setLoadingOrderTest(true);
                      setLoadingDisease(true);
                      setTimeout(() => {
                        setLoadingOrderTest(false);
                        setLoadingDisease(false);
                      }, 2000);
                    }}
                  >
                    Tạo ghi chú lâm sàng
                  </Button>
                  <Button
                    className={!onlyTest ? "" : "button-active"}
                    type="default"
                    size="large"
                    style={{ marginLeft: "1rem" }}
                    onClick={() => {
                      setOnlyTest(true);
                      handleCheckFileUpload();
                      setLoadingUpload(true);
                      setTimeout(() => {
                        setLoadingUpload(false);
                      }, 2000);
                    }}
                  >
                    Chọn ảnh
                  </Button>
                </Form.Item>
                <div className="order-content ">
                  {!onlyTest ? (
                    <Form.Item
                      name="examination_results"
                      // label="Kết quả khám"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Kết quả khám không được bỏ trống",
                      //   },
                      // ]}
                    >
                      <div className="form-center">
                        <label> Kết quả khám</label>
                        <TextArea
                          showCount
                          maxLength={500}
                          rows={4}
                          placeholder="Ghi chú"
                          name="examination_results"
                        />
                      </div>
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  {!onlyTest ? (
                    <Form.Item
                      name="note"
                      // label="Lời dặn"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Lời dặn không được bỏ trống",
                      //   },
                      // ]}
                    >
                      <div className="form-center">
                        <label> Lời dặn</label>
                        <TextArea
                          showCount
                          maxLength={500}
                          rows={4}
                          placeholder="Ghi chú"
                          name="note"
                        />
                      </div>
                    </Form.Item>
                  ) : (
                    ""
                  )}

                  {onlyTest ? (
                    <Form.Item>
                      <Skeleton loading={loadingUpload} active avatar>
                        <Upload
                          listType="picture-card"
                          showUploadList={true}
                          beforeUpload={onBeforeUpload}
                          customRequest={() => {}}
                          onChange={(e) => {
                            setCheckFileUpload(e.fileList.length);
                          }}
                          onRemove={onRemove}
                          multiple={true}
                          accept=".jpg, .jpeg, .png"
                        >
                          <div>
                            <PlusOutlined />
                            <div style={{ marginTop: 4 }}>Chọn ảnh</div>
                          </div>
                        </Upload>
                      </Skeleton>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                </div>
              </Card>

              {!onlyTest ? (
                <div>
                  <CardDisease
                    loading={loading}
                    loadingDisease={loadingDisease}
                    setSearchQueryDisease={debouncedSearch}
                    diseaseCategories={diseaseCategories}
                    selectedDiseases={handlePopupScroll}
                    handleSelectedDiseases={handleSelectedDiseases}
                    total={total}
                  />
                </div>
              ) : (
                ""
              )}
            </Col>
            <Col span={12}>
              <CardSave loading={loading} />

              <CardTypeTest
                loading={loading}
                isImageConfirmationNeeded={onlyTest}
                categories={testCategories}
                selectedValues={selectedTests}
                setSearchQuery={""}
                messageErrorForTypeText={typeTextErrorMessage}
                handleCheckboxChange={handleCheckboxChange}
                handleSearch={handleSearch}
                loadingOrdertest={loadingOrderTest}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default ClinicalNotesAdd;
