import { FilePdfOutlined } from "@ant-design/icons";
import { List, Tag, Card, Image } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "src/services";

function ClinicalNotesDetail(props) {
  const { number, subclinicalsId, patient_id } = props;
  const [loading, setLoading] = useState(false);
  const [sublinical, setSublinical] = useState([]);
  let { id }: any = useParams();

  useEffect(() => {
    fetchData();
  }, [number]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data: rPatitent }: any = await api.getSubclinicalsDetail(subclinicalsId, id ? id : patient_id);
      if (rPatitent) {
        setSublinical(rPatitent?.result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      title="Kết quả"
      size="small"
      loading={loading}
      style={{ marginBottom: 16 }}
    >
      { sublinical && sublinical.length > 0 ? (
        <List
          className="list-detail-subclinical"
          dataSource={sublinical}
          renderItem={(item: any) => (
            <List.Item className="list-detail-subclinical-item">
              <div className="list-detail-subclinical-doctor">
                <span className="font-bold">Bác sĩ: </span>
                {item?.doctor?.name}
              </div>
              <div className="list-detail-subclinical-file">
                {
                  item.images && item.images.length > 0 && item.images.map((image: any) => (
                  <Image
                    width={100}
                    height={100}
                    style={{ objectFit: 'cover' }}
                    src={image.url}
                  />
                ))}
              </div>
              <div className="list-detail-subclinical-file">
                {
                  item.file && item.file.length && item.file.map((pdf: any) => (
                    <Tag color="blue">
                      <div className="tag-subclinical">
                        <a href={pdf.url} target="__blank">
                          <FilePdfOutlined />
                          {item.day} tháng {item.month} {item.year}
                        </a>
                      </div>
                    </Tag>
                ))}
              </div>
            </List.Item>
          )}
        />
      ) : (
        <div>
          Không tìm thấy thông tin
        </div>
      )}
    </Card>
  );
}

export default ClinicalNotesDetail;
