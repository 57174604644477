import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table, Tabs, message } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CheckCircleOutlined, ExclamationCircleOutlined, MessageOutlined, StopOutlined, WechatOutlined } from "@ant-design/icons";
import { api } from "src/services";
import { contains, utils } from "src/utils";
import _ from "lodash";
import {
  AvatarName,
  BreadcrumbComponent,
  IconCheckNow,
  IconDelete,
  IconTest,
  ModalDelete,
  SpanMailTo,
  SpanPhone,
} from "src/components";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import { useDispatch } from "react-redux";
import { allActions } from "src/redux";
const { confirm } = Modal;

export default function MyPatient() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [listPatients, setListPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [provinceLoading, setProvinceLoading] = useState(false);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [wardLoading, setWardLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [isOpenModalPatientInfoRegister, setOpenModalPatientInfoRegister] = useState(false);
  const [formDelete] = Form.useForm();
  const [formPatientInfoRegister] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPatient();
  }, [searchValue]);

  const handleViewDetailPatient = (id) => {
    history.push(`${path}/schedule-detail/${id}`);
  };

  const handleChat = async(user:any)=>{
    dispatch(allActions.option.saveOption(user));
  }
  
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Hành động",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text: string, record: any) => (
        <>
          <Row style={{ marginBottom: "2px" }}>
            <Col span={12}>
              <Button
                type="default"
                title="Khám"
                style={{ width: "100%" }}
                onClick={() => handleClickTest(record.id)}
              >
                Khám
              </Button>
            </Col>
            <Col span={12}>
              <Button
                  type="default"
                  title="Xét nghiệm"
                  style={{ width: "100%" }}
                  onClick={() => handleClickTest(record.id)}
                >
                  Xét Nghiệm
              </Button>
            </Col>
          </Row>
          <Row style={{ marginBottom: "2px" }}>
            <Col span={12}>
              <Button
                  type="default"
                  title="Liên hệ"
                  style={{ width: "100%" }}
                  onClick={()=> handleChat(record)}
                >
                  Liên hệ
              </Button>
            </Col>
            <Col span={12}>
              <Button
                  title="Xoá"
                  type="primary"
                  style={{ width: "100%" }}
                  onClick={() => {
                    setOpenModalDelete(true);
                    formDelete.setFieldsValue({
                      id: record.id,
                      status: record.status,
                    });
                  }}
                  danger
                >
                  Xoá
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      title: "Tên",
      dataIndex: "avatar",
      key: "avatar",
      width: 150,
      render: (avatar, record) => (
        <div>
          <AvatarName
            avatar={avatar}
            name={record?.name}
            profileDoctor={() => {
              history.push(`/my-patient/schedule-detail/${record.id}`);
            }}
            viewDetail={() => handleViewDetailPatient(record?.id)}
          />
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 120,
      render: (text: string, record: any) => <SpanMailTo mail={text} />,
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      width: 100,
      render: (text: string, record: any) => <SpanPhone phone={text} />,
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address",
      width: 350,
    },
    {
      title: "Giới tính",
      dataIndex: "gender",
      key: "gender",
      width: 100,
      render: (text: string, record: any) => (
        <span>{text === "male" ? "Nam" : text === "female" ? "Nữ" : ""}</span>
      ),
    },
    {
      title: "Tuổi",
      dataIndex: "dob",
      key: "dob",
      width: 50,
      render: (text: string, record: any) => utils.calculateAge(text),
    },
    {
      title: "Cài app",
      dataIndex: "is_register",
      key: "is_register",
      width: 60,
      render: (text: number, record: any) => (
        <>
          { 
            text === 1 
            ? <CheckCircleOutlined style={{ cursor: 'default', color: 'green' }} />
            : <StopOutlined style={{cursor: 'default'}}/> 
          }
        </>
      ),
    },
  ];
  const handleClickTest = (id) => {
    history.push(`${path}/order-patient-test/${id}`);
  };

  const fetchPatient = async (page = 1, pageSize = 10) => {
    try {
      handleProvinces();
      setLoading(true);
      const { data: rPatitent, meta }: any = await api.getMyPatient(contains.patientType, page, searchValue, pageSize);
      setLoading(false);
      if (rPatitent) {
        let patientArr = rPatitent.map((el: any) => ({ key: el?.id, ...el }));
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
        setListPatients(patientArr);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchPatient(pagination.current, pagination.pageSize);
  };

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelect: (record: any, selected: any, selectedRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
    onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
      setSelectedRows(selectedRows.map((el: any) => el?.id));
    },
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteMyPatient(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      fetchPatient();
      setLoading(false);
      handleCanel();
    }
  };

  const handleCanel = () => {
    setOpenModalDelete(false);
  };

  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteAllMyPatient(selectedRows);
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          console.log(error);
          message.error(error);
        } finally {
          setSelectedRows([]);
          fetchPatient();
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/my-patient", name: "Bệnh nhân của tôi" },
  ];

  const handleCancel = () => {
    setOpenModalPatientInfoRegister(false);
  };

  const handlePatientInfoRegister = async (values) => {
    const formData = {
      name: values.name,
      email: values.email,
      dob: values.dob,
      gender: values.gender,
      phone: values.phone,
      province_id: values.province_id,
      district_id: values.district_id,
      ward_id: values.ward_id,
      short_address: values.short_address,
    };

    try {
      setLoading(true);
      const response: any = await api.patientInfoRegister(formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchPatient();
      setLoading(false);
      handleCancel();
    }
  };

  const handleProvinces = async () => {
    try {
      setProvinceLoading(true);
      setDistricts([]);
      setWards([]);
      const response: any = await api.getAllProvinces();
      if (response.error) {
        message.error(response.message);
      } else {
        setProvinces(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProvinceLoading(false);
    }
  };
  
  const handleProvinceChange = async (provinceId: number) => {
    try {
        setDistrictLoading(true);
        if (formPatientInfoRegister) {
          formPatientInfoRegister.resetFields(['district_id', 'ward_id']);
        }
        setDistricts([]);
        setWards([]);
        setWardLoading(true);
        setSelectedProvince(provinceId);
        
        const response: any = await api.getDistrictsByProvince(provinceId);
        if (response.error) {
            message.error(response.message);
        } else {
            setDistricts(response.data);
        }
    } catch (error) {
        console.log(error);
    } finally {
        setDistrictLoading(false);
        setWardLoading(false);
    }
  };

  const handleDistrictChange = async (districtId: number) => {
    try {
      setWardLoading(true);
      if (formPatientInfoRegister) {
        formPatientInfoRegister.resetFields(['ward_id']);
      }
      setWards([]);
      setSelectedDistrict(districtId);
      const response: any = await api.getWardsByDistrict(districtId);
      if (response.error) {
        message.error(response.message);
      } else {
        setWards(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setWardLoading(false);
    }
  };  
  const addFriend = async()=>{
    try {
      const res = await api.addFreind(searchValue);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <div className="admin-table my-patient">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div className="wp-action" style={{ marginBottom: "1rem" }}>
          <div className="wp-action-left">
            <Button
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              style={{
                marginRight: ".5rem",
              }}
              type="primary"
              danger
            >
              Xoá
            </Button>
            {
              (!process.env.NODE_ENV ||  process.env.NODE_ENV === 'development') && 
                <Button
                  onClick={addFriend}
                  type="primary"
                  danger
                >
                  Thêm bạn
                </Button>
            }
            <Input
              style={{
                width: 250,
                marginRight: ".5rem",
              }}
              allowClear
              onChange={_.debounce(function (e: any) {
                setSearchValue(e.target.value);
              }, 300)}
              placeholder="Tìm kiếm"
            />
          </div>
          <div className="wp-action-right">
            <Button
              onClick={() => {
                setOpenModalPatientInfoRegister(true);
              }}
              type="primary"
            >
              Tạo thông tin bệnh nhân
            </Button>
          </div>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={listPatients}
          pagination={tableParams.pagination}
          loading={loading}
          onChange={handleTableChange}
        />
        <ModalDelete
          open={openModalDelete}
          cancel={handleCanel}
          form={formDelete}
          handleSubmit={handleDelete}
          loading={loading}
          title={"Xóa"}
        />

        <Modal
          title="Tạo thông tin bệnh nhân"
          open={isOpenModalPatientInfoRegister}
          footer={null}
          onCancel={handleCancel}
        >
          <Form            
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            form={formPatientInfoRegister} 
            onFinish={handlePatientInfoRegister}>
            <Form.Item
              name="name"
              label="Tên"
              rules={[{ required: true, message: 'Vui lòng nhập tên bệnh nhân' }]}
            >
              <Input placeholder="Nhập tên bệnh nhân" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              rules={[
                { type: 'email', message: 'Vui lòng nhập email hợp lệ' },
                { max: 60, message: 'Email không được vượt quá 60 ký tự' },
                { min: 6, message: 'Email phải có ít nhất 6 ký tự' },
                { required: false }
              ]}
            >
              <Input placeholder="Nhập email" />
            </Form.Item>

            <Form.Item
              name="dob"
              label="Ngày sinh"
              rules={[
                { required: true, message: 'Vui lòng chọn ngày sinh' },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }

                    const today = new Date();
                    const birthDate = new Date(value);
                    let age = today.getFullYear() - birthDate.getFullYear();
                    const monthDiff = today.getMonth() - birthDate.getMonth();
                    const dayDiff = today.getDate() - birthDate.getDate();

                    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                      age--;
                    }

                    if (age < 0) {
                      return Promise.reject(new Error('Vui lòng chọn đúng ngày sinh, không chọn ngày trong tương lai!'));
                    }

                    return Promise.resolve();
                  }
                }
              ]}
            >
              <DatePicker placeholder="Chọn ngày sinh" style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item
              name="gender"
              label="Giới tính"
              rules={[{ required: true, message: 'Vui lòng chọn giới tính' }]}
            >
              <Select placeholder="Chọn giới tính">
                <Select.Option value="male">Nam</Select.Option>
                <Select.Option value="female">Nữ</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="phone"
              label="Số điện thoại"
              rules={[
                { required: true, message: 'Vui lòng nhập số điện thoại' },
                { type: 'string', message: 'Số điện thoại phải là một số' },
                { pattern: /^0[3|5|7|8|9]\d{8}$/, message: 'Số điện thoại không hợp lệ' },
              ]}
            >
              <Input placeholder="Nhập số điện thoại" />
            </Form.Item>

            <Form.Item
              name="province_id"
              label="Tỉnh/Thành phố"
              rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
            >
              <Select
                placeholder="Chọn tỉnh/thành phố"
                onChange={handleProvinceChange}
                loading={provinceLoading}
              >
                {provinces.map((province) => (
                  <Option key={province.matp} value={province.matp}>
                    {province.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="district_id"
              label="Quận/Huyện"
              rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
            >
              <Select
                placeholder="Chọn quận/huyện"
                onChange={handleDistrictChange}
                disabled={!selectedProvince}
                loading={districtLoading}
              >
                {districts.map((district) => (
                  <Option key={district.maqh} value={district.maqh}>
                    {district.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="ward_id"
              label="Phường/Xã"
              rules={[{ required: true, message: 'Vui lòng chọn tinh/thanh'}]}
            >
              <Select placeholder="Chọn phường/xã" disabled={!selectedDistrict} loading={wardLoading}>
                {wards.map((ward) => (
                  <Option key={ward.xaid} value={ward.xaid}>
                    {ward.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="short_address"
              label="Địa chỉ"
              rules={[{ required: true, message: 'Vui lòng nhập địa chỉ' }]}
            >
              <TextArea placeholder="Nhập địa chỉ" />
            </Form.Item>

            <Form.Item style={{ textAlign: "right", marginBottom: "unset" }}>
              <Button type="primary" loading={loading} htmlType="submit">
                Lưu
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
