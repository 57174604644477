import { CheckCircleOutlined, CloseCircleOutlined, FolderViewOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Empty,
  Form,
  List,
  Pagination,
  Row,
  Space,
  Tag,
  Tooltip,
  message,
} from "antd";
import { useRef, useState } from "react";
import {
  DeleteConfirmationModal,
  IconCheck,
  IconLocation,
  IconPhone,
  PaymentConfirmationModal,
  SpanPhone,
  CompletedConfirmationModal,
  ChangeScheduleBookingModal,
  RefundConfirmationModal,
  CreateAppointmentModal,
} from "src/components";
import { api } from "src/services";
import { utils } from "src/utils";

function ListPatientBooking({
  listPatientBooking,
  handleClickPatient,
  loading,
  fetchListPatitent,
  setLoading,
  activePatient,
  current,
  setCurent,
  pageSize,
  setPageSize,
  total,
  currentTime,
}) {
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [isConfirmPaymentModal, setConfirmPaymentModal] = useState(false);
  const [isCompletedModal, setIsCompletedModal] = useState(false);
  const [isChangeModal, setChangeModal] = useState(false);
  const [isConfirmRefundModal, setConfirmRefundModal] = useState(false);
  const [formDelete] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [formCompleted] = Form.useForm();
  const formChange = useRef<any>(null);
  const [bookingUpdate, setBookingUpdate]: any = useState(false);
  const [bookingConfirmPayment, setBookingConfirmPayment]: any = useState('');
  const [bookingConfirmRefund, setBookingConfirmRefund]: any = useState('');

  const handleCancel = () => {
    setDeleteModal(false);
    setConfirmPaymentModal(false);
    setIsCompletedModal(false);
    setChangeModal(false);
    setConfirmRefundModal(false);
  };

  const handleConfirmPayment = async (values: any) => {
    const formData = new FormData();
    formData.append('payment_confirm', values?.status); 
    try {
      setLoading(true);
      const response: any = await api.updateBookingPayment(values.id, formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleDelete = async (values: any) => {
    try {
      setLoading(true);
      const response: any = await api.updateBookingStatus(
        values.id,
        "canceled"
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleConfirmCompleted = async (values: any) => {
    try {
      setLoading(true);
      const response: any = await api.updateBookingStatus(
        values.id,
        "done"
      );
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const handleClickShowModalChange = (booking: any) => {
    setChangeModal(true);
    setBookingUpdate(booking);
  }

  const handleClickShowModalConfirmRefund = (booking: any) => {
    setConfirmRefundModal(true);
    setBookingConfirmRefund(booking);
  }

  const handleClickShowModalConfirmPayment = (booking: any) => {
    setConfirmPaymentModal(true);
    formUpdate.setFieldsValue({
      id: booking.id,
    });
    setBookingConfirmPayment(booking);
  }

  return (
    <>
      <List
        locale={{
          emptyText: (
            <Empty description="Không có bệnh nhân đặt lịch khám trong ngày bạn chọn" />
          ),
        }}
        className="calendar-patient-list"
        loading={loading}
        header={
            <>
              <div className="font-bold">
                  Danh sách lịch khám
              </div>
              <CreateAppointmentModal 
                currentTime={currentTime}
                fetchListPatitent={fetchListPatitent}
              />
            </>
        }
        size="small"
        bordered
        footer={
          total > 10 ? (
            <Pagination
              style={{ float: "right" }}
              size="small"
              defaultCurrent={1}
              total={total}
              current={current}
              defaultPageSize={10}
              pageSize={pageSize}
              onChange={(current, pageSize) => {
                setCurent(current);
                setPageSize(pageSize);
              }}
            />
          ) : null
        }
        dataSource={listPatientBooking}
        renderItem={(item: any) => (
          <List.Item
            key={item?.member?.id}
            onClick={() => handleClickPatient(item?.member)}
            className={`patient-list-item ${activePatient && activePatient.id === item?.member?.id ? "active" : ""} ${['canceled', 'done', 'rescheduled', 'expired'].includes(item.status.value) && !['waiting_accept', 'paid'].includes(item.payment_confirm.value) ? "no-active" : ""}`}
            style={{ cursor: ['canceled', 'done', 'rescheduled', 'expired'].includes(item.status.value) && !['waiting_accept', 'paid'].includes(item.payment_confirm.value) ? "not-allowed" : "" }}
          >
            <List.Item.Meta
              avatar={
                <Avatar src={item?.member?.avatar} />
              }
              title={
                <div className="patient-booking-name">
                  <span>
                    {item?.name}{" "}
                    <span> - {utils.calculateAge(item?.dob)} tuổi</span>
                  </span>
                  <div className="patient-booking-name-time">
                    <span>Stt khám: {item?.order_number}</span> -{" "}
                    <span>{item?.slot_start_time}</span>
                  </div>
                </div>
              }
              description={
                <Row className="patient-booking-detail">
                  <Col span={16} className="patient-booking-detail-info">
                    <Row>
                      <Col span={8}>
                        {item?.phone ? (
                          <>
                            <IconPhone />
                            <span className="patient-booking-detail-info-text">
                              {<SpanPhone phone={item?.phone} />}
                            </span>
                          </>
                        ) : null}
                      </Col>
                      <Col span={16}>
                        {item?.payment_method?.label ? <IconCheck /> : null}
                        <span className="patient-booking-detail-info-text">
                          {item?.payment_method?.label}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        {item?.member?.address ? (
                          <div>
                            <IconLocation />
                            <span className="patient-booking-detail-info-text">
                              {item?.member?.address}
                            </span>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                  <Col span={24}>
                    <Row style={{ alignItems: "center" }}>
                      <Col span={22}>
                        <Space size={[0, 8]} wrap>
                          {item.status.value === "done" && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#87d068"
                            >
                              Đã khám
                            </Tag>
                          )}

                          {item.status.value === "rescheduled" && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#000000"
                            >
                              Đã lên lịch mới
                            </Tag>
                          )}

                          {['canceled', 'expired'].includes(item.status.value) && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#F02F34"
                            >
                              { item?.status?.label }
                            </Tag>
                          )}

                          {item.is_enable_accept && (
                            <Tag
                              icon={<CheckCircleOutlined />}
                              color="#d76f30"
                            >
                              Chờ xác nhận từ bệnh nhân
                            </Tag>
                          )}

                          {['accept'].includes(item.status.value) && !item.is_enable_accept && (
                            <Tag
                              color="#108ee9"
                              onClick={() => {
                                setIsCompletedModal(true);
                                formCompleted.setFieldsValue({
                                  id: item.id,
                                });
                              }}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              Hoàn thành khám
                            </Tag>
                          )}

                          {['accept'].includes(item.status.value) && !item.is_enable_accept && (
                            <Tag
                              color="#108ee9"
                              onClick={() => handleClickShowModalChange(item)}
                              style={{
                                cursor: 'pointer'
                              }}
                            >
                              Thay đổi lịch
                            </Tag>
                          )}
                        </Space>

                        {item.payment_method.value === "banking" && (
                          <Space size={[0, 8]} wrap>
                            {item.payment_confirm.value === "paid" && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="success"
                              >
                                Đã thanh toán
                              </Tag>
                            )} 
                            
                            {item.payment_confirm.value === "waiting_accept" && !['canceled', 'reschedules', 'done', 'expired'].includes(item.status.value) && (
                              <Tag
                                color="#2db7f5"
                                onClick={() => handleClickShowModalConfirmPayment(item)}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                Xác nhận thanh toán
                              </Tag>
                            )}

                            {item.payment_confirm.value === "unpaid" && (
                              <Tag color="#f50">
                                Chưa thanh toán
                              </Tag>
                            )}

                            {['canceled', 'rescheduled'].includes(item.status.value) && ['waiting_accept', 'paid'].includes(item.payment_confirm.value) && (
                              <Tag 
                                color="#108ee9"
                                onClick={() => handleClickShowModalConfirmRefund(item)}
                                style={{
                                  cursor: 'pointer'
                                }}
                              >
                                Xác nhận đã hoàn tiền
                              </Tag>
                            )}

                            {item.payment_confirm.value === "refunded" && (
                              <Tag
                                icon={<CheckCircleOutlined />}
                                color="#87d068"
                              >
                                Đã hoàn tiền
                              </Tag>
                            )}
                          </Space>
                        )}
                      </Col>
                      <Col span={2}>
                        <div className="d-flex gap-1">
                          {!['canceled', 'done', 'rescheduled', 'expired'].includes(item.status.value) && (
                              <Tooltip placement="top" title="Hủy đặt lịch">
                                <Button
                                  type="primary"
                                  shape="circle"
                                  danger
                                  onClick={() => {
                                    setDeleteModal(true);
                                    formDelete.setFieldsValue({
                                      id: item.id,
                                    });
                                  }}
                                >
                                  <CloseCircleOutlined />
                                </Button>
                              </Tooltip>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
            />
          </List.Item>
        )}
      />
      <DeleteConfirmationModal
        isVisible={isDeleteModal}
        onCancel={handleCancel}
        onDelete={handleDelete}
        loading={loading}
        form={formDelete}
      />

      <CompletedConfirmationModal
        isVisible={isCompletedModal}
        onCancel={handleCancel}
        onFinishUpdate={handleConfirmCompleted}
        loading={false}
        form={formCompleted}
      />
      {
        bookingUpdate &&
        <ChangeScheduleBookingModal
          isOpenModal={isChangeModal}
          handleCancel={handleCancel}
          formChange={formChange}
          booking={bookingUpdate}
          patientInfo={bookingUpdate?.member}
          fetchListPatitent={fetchListPatitent}
        />
      }

      {
        bookingConfirmPayment && 
        (
          <PaymentConfirmationModal
            isVisible={isConfirmPaymentModal}
            handleCancel={handleCancel}
            onFinishConfirmPayment={handleConfirmPayment}
            loading={loading}
            form={formUpdate}
            booking={bookingConfirmPayment}
          />
        )
      }

      {
        bookingConfirmRefund && 
        (
          <RefundConfirmationModal
            isVisible={isConfirmRefundModal}
            handleCancel={handleCancel}
            booking={bookingConfirmRefund}
            fetchListPatitent={fetchListPatitent}
          />
        )
      }
    </>
  );
}

export default ListPatientBooking;
