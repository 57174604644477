import { Button, Divider, Form, Input, List, Modal, Spin } from "antd";
import React from "react";

const NotificationItem = ({
    notifications,
    loadingNotification,
    nextCursor,
    loadMore,
    utils,
    loading,
    handleReadNotification
}) => {
    const getMessage = (data:any) => {
        const params = data?.params ?? {};
        let messageTemplate = data?.message ?? "";
    
        // Assuming params is an object with key-value pairs
        Object.entries(params).forEach(([key, value]) => {
            messageTemplate = messageTemplate.replaceAll(`:${key}`, value);
        });
    
        return messageTemplate;
    };
  return (
    <div>
        <List
        dataSource={notifications}
        loading={loading}
        loadMore={loadMore}
        renderItem={(item: any) => (
            <List.Item style={{ width: "100%" }}>
            {item?.is_read ? (
                <div className="notification-group"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleReadNotification(item)}
                >
                    <div className="notification-item">
                        <div style={{ fontWeight: "500" }}>
                        {utils.formatDate(item?.created_at)}
                        </div>
                        <div className="notification-item-content">
                            { getMessage(item?.data) }
                        </div>
                    </div>
                </div>
            ) : (
                <div className="notification-group"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleReadNotification(item)}
                >
                    <div
                        className="notification-item"
                        style={{ fontWeight: "700", flexBasis: "90%" }}
                    >
                        <div>{utils.formatDate(item?.created_at)}</div>
                        <div className="notification-item-content">
                            { getMessage(item?.data) }
                        </div>
                    </div>
                    <span className="dot"></span>
                </div>
            )}
            </List.Item>
        )}
        />
        {loadingNotification && (
        <div
            style={{
            display: "block",
            marginTop: 10,
            marginBottom: 10,
            marginLeft: "auto",
            marginRight: "auto",
            width: 50,
            }}
        >
            <Spin />
        </div>
        )}
        {nextCursor == null && (
        <Divider plain>
            Hết rồi, không còn thông báo nào nữa 😥
        </Divider>
        )}
    </div>
  );
};

export default NotificationItem;
