import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getNewsPost<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/posts/new-posts`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getPostDetail<T>(postId:any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/posts/${postId}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getCategoriesPost<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/categories/list`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getPostByCategoryies<T>(categoryId:any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/posts/by-category/${categoryId}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getPostByCategory<T>(categoryId:any, page:any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/posts/by-category/${categoryId}?page=${page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
const data = {
  getNewsPost,
  getPostDetail,
  getCategoriesPost,
  getPostByCategoryies,
  getPostByCategory
};

export default data;
