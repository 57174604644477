import React, { useEffect, useState } from "react";
import { Card, List } from 'antd';
import { NavLink } from "react-router-dom";
import { api } from "src/services";
import PostCategory from "./PostCategory";

const News = () => {

  /* INIT STATE */
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchCategoriesPost = async () =>{
    try {
      setLoading(true);
      const { data }:any = await api.getCategoriesPost();
      if (data && data.length > 0) {
        setCategories(data);
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false);
  }

  /* EFFECT CHANGE RENDER */

  useEffect(()=>{
    fetchCategoriesPost();
  },[])

  return (
    <div>
        <List 
          dataSource={categories}
          loading={loading}
          renderItem={(item:any, index:any)=>(
            <PostCategory 
              key={index}
              index={index}
              category={item}
            />
          )}
        />
    </div>
  );
};

export default News;
