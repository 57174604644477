import { Button, Col, Image, List, Modal, Row, Skeleton, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { api } from 'src/services';
const perPage = 20;

function ListRecentActivityModal({ open, setIsOpenModalRecentActivity, patient_id }) {
    const [data, setData] = useState([]);
    const [isLastPage, setIsLastPage] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const [initLoading, setInitLoading] = useState(true);
    const [list, setList] = useState([]);


    const renderType = (type: string, categoryName: string = '') => {
        switch (type) {
            case 'prescription':
                return 'Toa thuốc';
            case 'subclinical':
                return `Kết quả ${categoryName || 'cận lâm sàng'}`;
            case 'clinical_notes':
                return 'Ghi chú lâm sàng';
            case 'order_patient_test':
                return 'Phiếu chỉ đinh xét nghiệm';
            case 'test_result':
                return 'Kết quả xét nghiệm';
        }
    };

    const fetchData: any = (currentPage: number = 1) => {
        try {
            const page = currentPage ? currentPage : 1;
            return new Promise((resolve, reject) => {
                api.fetchTimeline(patient_id, page, perPage)
                    .then((response: any) => {
                        const { data: orginalData = [], meta = {} }: any = response;
                        if (!meta?.last_page || meta?.last_page === page) {
                            setIsLastPage(true);
                        }
                        resolve(orginalData);
                    })
                    .catch((error: any) => {
                        if (error?.data?.error) {
                            message.error('Xảy ra lỗi. Vui lòng reload lại!')
                        }
                        reject(error);
                    });
            });
        } catch (err: any) {
            console.log(err.data);
        }
    };

    useEffect(() => {
        fetchData().then((rData: any) => {
            setInitLoading(false);
            setData(rData);
            setList(rData);
        });
    }, []);


    const handleClickButtonLoadMore = () => {
        setList(data.concat([...new Array(perPage)].map(() => ({ loading: true }))));
        if (!isLastPage) {
            const newPage = page + 1;
            setPage(newPage);
            fetchData(newPage).then((rData: any) => {
                const newData = data.concat(rData);
                setData(newData);
                setList(newData);
            });
        }
    };

    const loadMore =
        !isLastPage ?
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button type="primary" onClick={handleClickButtonLoadMore}>Tải thêm</Button>
            </div> : "";

    return (
        <Modal
            open={open}
            onCancel={() => setIsOpenModalRecentActivity(false)}
            footer={null}
            width={800}
            centered
            title="Hoạt động gần đây"
            className="modal-scroll"
        >
            <List
                className='list-recent-activity'
                size="small"
                bordered
                loading={initLoading}
                loadMore={loadMore}
                dataSource={list}
                renderItem={(item: any) => (
                    <>
                        {item.images?.length > 0 ?
                            <List.Item style={{ display: 'block' }}>
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <div className='recent-activity-item'>
                                        <Row>
                                            <Col span={10}>
                                                {renderType(item.type, item.category?.name)}
                                            </Col>
                                            <Col span={6}>
                                                {item.doctor?.name}
                                            </Col>
                                            <Col span={6}>
                                                {moment(item.created_at).format('DD, [tháng] M, Y')}
                                            </Col>
                                            <Col span={2}>
                                                {moment(item.created_at).format('hh:mm')}
                                            </Col>
                                        </Row>
                                        <Row>
                                            {item?.images?.map((item: any, index: any) => {
                                                return (
                                                    <Col span={2}>
                                                        <Image
                                                            style={{ width: '100%', height: '60px', objectFit: 'cover' }}
                                                            src={item.url}
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Skeleton>
                            </List.Item>
                            :
                            <List.Item>
                                <Skeleton avatar title={false} loading={item.loading} active>
                                    <a href={item.file} target="_blank" className='recent-activity-item' style={{ width: "100%" }}>
                                        <Row>
                                            <Col span={10}>
                                                {renderType(item.type, item.category?.name)}
                                            </Col>
                                            <Col span={6}>
                                                {item.doctor?.name}
                                            </Col>
                                            <Col span={6}>
                                                {moment(item.created_at).format('DD, [tháng] M, Y')}
                                            </Col>
                                            <Col span={2}>
                                                {moment(item.created_at).format('hh:mm')}
                                            </Col>
                                        </Row>
                                    </a>
                                </Skeleton>
                            </List.Item>
                        }
                    </>
                )}
            />
        </Modal>
    );
}

export default ListRecentActivityModal;