

import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getChatList<T>(
  per_page:number,
  q: string|null
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/chat/list?per_page=${per_page}&q=${q}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getMessages<T>(
  chatId:any,
  cursor:any = ''
): NetworkPromiseResponse<T> {
  let url = `v1/messages/${chatId}`;
  if(cursor){
    url = `v1/messages/${chatId}?cursor=${cursor}`
  }
  return new Promise((resolve, reject) => {
    network
      .get(url)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function updateChat<T>(id: number, values: any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/chat/${id}`, values)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res as any);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function sendMessage<T>(chat_id:any, content : any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/messages`,{chat_id, content})
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getChat<T>(uid:any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/chat/${uid}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}
const data = {
  getChatList,
  getMessages,
  updateChat,
  sendMessage,
  getChat
};

export default data;
