import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal, Table, message } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  AddButton,
  BreadcrumbComponent,
  IconDelete,
  ModalDelete,
  OnDeleteButton,
  SearchInput,
  CreateSampleOrderTestModal,
  DetailSampleOrderTestModal,
  IconViewDetail,
} from "src/components";
import { api } from "src/services";

function SampleOrderTest(props) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalCreateVisible, setIsModalCreateVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [formDelete] = Form.useForm();
  const [form] = Form.useForm();
  const [dataSampleOrderTest, setDataSampleOrderTest] = useState([]);
  const [sampleOrderTest, setSampleOrderTest]: any = useState({});
  const { confirm } = Modal;
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 1,
    },
  });

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "#", name: "Cài đặt yêu cầu xét nghiệm mẫu" },
  ];
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRowKeys);
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows.map((el) => el?.id));
    },
  };
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Tên",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Ngày",
      dataIndex: "date_format",
      key: "date_format",
    },
    {
      title: "Hoạt động",
      render: (text: string, record: any) => (
        <>
          <IconViewDetail onClick={() => handleClickEdit(record)} />
          <IconDelete
            onClick={() => {
              setIsModalDeleteVisible(true);
              formDelete.setFieldsValue({
                id: record.id,
                status: record.status,
              });
            }}
          />
        </>
      ),
    },
  ];
  const handleClickEdit = (item) => {
    setIsModalUpdateVisible(true);
    setSampleOrderTest(item);
  };
  const fetchSampleOrderTest = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const res: any = await api.getSampleOrderTestByDoctor(
        page,
        pageSize,
        searchValue
      );
      if (res && res.data) {
        let sampleOrderTestArr = res.data.map((el: any) => ({
          key: el.id,
          ...el,
        }));
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: res.meta.per_page,
            total: res.meta.total,
          },
        });
        setDataSampleOrderTest(sampleOrderTestArr);
      } else {
        setDataSampleOrderTest([]);
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchSampleOrderTest(pagination.current, pagination.pageSize);
  };
  useEffect(() => {
    fetchSampleOrderTest();
  }, [searchValue]);
  const handleCancel = () => {
    setIsModalCreateVisible(false);
    setIsModalUpdateVisible(false);
    setIsModalDeleteVisible(false);
  };
  const handleDeleteSampleOrderTest = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteSampleOrderTest(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error.message);
    } finally {
      fetchSampleOrderTest();
      handleCancel();
      setLoading(false);
    }
  };
  const onConfirmDelete = () => {
    confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn các chỉ định mẫu này không",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          const response: any = await api.deleteListSampleOrderTest(
            selectedRows
          );
          if (response.error) {
            message.error(response.message);
          } else {
            message.success(response.message);
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setLoading(false);
          fetchSampleOrderTest();
          setSelectedRows([]);
        }
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
  };
  return (
    <div className="admin-table sample-prescription">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="flex-fill">
        <div className="wp-action">
          <div className="wp-action-left">
            <OnDeleteButton
              onClick={onConfirmDelete}
              disabled={selectedRows.length === 0}
              title={"Xóa"}
            />
            <SearchInput
              onSearch={(value) => setSearchValue(value)}
              title={"Tìm kiếm toa thuốc mẫu"}
              width={250}
            />
          </div>
          <div className="wp-action-right">
            <AddButton
              onClick={() => {
                setIsModalCreateVisible(true);
                form.resetFields();
              }}
              title={"Thêm mới"}
            />
          </div>
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={dataSampleOrderTest}
          loading={loading}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </div>
      <CreateSampleOrderTestModal
        isModalCreateVisible={isModalCreateVisible}
        handleCancel={handleCancel}
        form={form}
        fetchSampleOrderTest={fetchSampleOrderTest}
      />
      {isModalUpdateVisible && (
        <DetailSampleOrderTestModal
          isModalUpdateVisible={isModalUpdateVisible}
          handleCancel={handleCancel}
          sampleOrderTest={sampleOrderTest}
          fetchSampleOrderTest={fetchSampleOrderTest}
        />
      )}
      <ModalDelete
        open={isModalDeleteVisible}
        cancel={handleCancel}
        form={formDelete}
        handleSubmit={handleDeleteSampleOrderTest}
        loading={loading}
        title={"Xóa yêu cầu xét nghiệm mẫu"}
      />
    </div>
  );
}

export default SampleOrderTest;
