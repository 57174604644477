import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "src/services";
import {
  ListClinicNote,
  ListOrderByTest,
  ListPrescription,
  ListSignsAndAllergy,
  ListSubclinical,
  PatientInformation,
} from "src/components";

export default function ScheduleDetail() {
  let { id }: any = useParams();
  const [patient, setPatient]: any = useState({});
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  useEffect(() => {
    getPatient();
  }, []);

  const getPatient = async () => {
    try {
      setLoading(true);
      const {data : _data}: any = await api.getMemberInfo(id);
      if(_data){
        setPatient(_data);
      }else{
        history.push("/");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
      <div className="schedule-detail">
        <div className="schedule">
          <div className="">
            <div className="signs-and-allergy-container">
              <ListSignsAndAllergy patient={patient} getPatient={getPatient} />
            </div>
          </div>

          <div className="schedule__detail">
            <div className="patient-information">
              {/* Thông tin bệnh nhân */}
              <PatientInformation loading={loading} patient={patient} />
            </div>
            <div className="schedule__detail_group">
              <Row gutter={8}>
                <Col span={12} key={1}>
                  {/* ghi chú lâm sàng  */}
                  <ListClinicNote patient_id={patient?.id} />
                </Col>
                <Col span={12} key={2}>
                  {/* Cận lâm sàng  */}
                  <ListSubclinical patient_id={patient?.id} />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12} key={1}>
                  {/*toa thuốc  */}
                  <ListPrescription
                    patient_id={
                      patient?.member?.id ? patient?.member?.id : patient?.id
                    }
                    patient_name={
                      patient?.member?.name
                        ? patient?.member?.name
                        : patient?.name
                    }
                  />
                </Col>
                <Col span={12} key={2}>
                  {/* Phiếu chỉ định xét nghiệm  */}
                  <ListOrderByTest patient_id={patient?.id} />
                </Col>
              </Row>
            </div>
          </div>
        </div>
    </div>
  );
}
