import { Modal, Form, Input, Button, Image } from "antd";
import { useState } from "react";

const PaymentConfirmationModal = ({
  isVisible,
  handleCancel,
  onFinishConfirmPayment,
  loading,
  form,
  booking
}) => {
  const [clickedButton, setClickedButton] = useState(null);

  const handleFinishForm = (values : any) => {
    values.status = clickedButton;
    onFinishConfirmPayment(values);
  };

  return (
    <Modal
      title="Xác nhận thanh toán"
      open={isVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <Form onFinish={handleFinishForm} form={form}>
        <Form.Item name="id" hidden>
          <Input type="text" />
        </Form.Item>
        <div className="text-semibold mb-1">Hình ảnh biên lai xác nhận thanh toán</div>
        {
          booking?.images_payment_member?.length ?
            <div className="d-flex gap-1 flex-wrap">
              <Image.PreviewGroup>
                {booking?.images_payment_member?.map((item: any, index: number) => (
                  <Image style={{ objectFit: 'cover' }} width={100} height={100} src={item} key={index} />
                ))
                }
              </Image.PreviewGroup>
            </div>
            :
            <div>Không có hình ảnh biên lai xác nhận thanh toán nào</div>
        }

        <div className="text-center mt-1">
          <Button className="mr-1" loading={loading} type="primary" danger htmlType="submit" onClick={() => setClickedButton('unpaid')}>
            Chưa thanh toán
          </Button>
          <Button type="primary" loading={loading} htmlType="submit" onClick={() => setClickedButton('paid')}>
            Xác nhận thanh toán
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default PaymentConfirmationModal;
