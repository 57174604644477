import { Button, Col, Empty, List, Modal, Pagination, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import {
  IconDateCreate,
  IconDocument,
  AvatarName,
  IconViewDetail,
  CardProfile,
} from "src/components";
import { ClinicalNotesAdd, ClinicalNotesDetail } from "src/views";

function ListClinicNote({ patient_id }) {
  const [loading, setLoading] = useState(false);
  const [listClinicNote, setListClinicNote] = useState([]);
  const [isModalOpenDetail, SetIsModalOpenDetail] = useState(false);
  const [props, setDataProps] = useState(false);
  const [isModalOpenCreate, setIsModalOpenCreate] = useState(false);
  const [fileUpload, setFileUpload] = useState([]);
  const [current, setCurent]: any = useState(1);
  const [pageSize, setPageSize]: any = useState(10);
  const [total, setTotal]: any = useState(10);
  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);
  const [dataProfile, setDataProfile] = useState("");
  const [number, setNumber] = useState(0);
  const limiter = 9;

  const fetchClinicNote = async (current?: 1, pageSize?: 10) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getClinicalNotes(
        patient_id,
        pageSize,
        current
      );
      if (rData) {
        const doctorData = rData.map((item) => ({
          id: item.id,
          avatar: item.doctor.avatar,
          name: item.doctor.name,
          doctorArr: item.doctor,
          examination_results: item.examination_results,
          images: item.images.length,
          created_at: item.day + "-" + item.month + "-" + item.year,
          key: item.id,
        }));
        setTotal(meta.total);
        setListClinicNote(doctorData);
      } else {
        setListClinicNote([]);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickViewDetail = async (id) => {
    SetIsModalOpenDetail(true);
    setLoading(true);
    const { data: rPatitent }: any = await api.getClinicalNotesDetail(id);
    if (rPatitent) {
      setDataProps(rPatitent);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClinicNote(current, pageSize);
  }, [patient_id, current, pageSize]);

  return (
    <>
      <List
        locale={{
          emptyText: <Empty description="Không tìm thấy ghi chú lâm sàng" />,
        }}
        loading={loading}
        className="list-clinic-note bg-white"
        size="small"
        header={
          <>
            <span className="font-bold">Ghi chú lâm sàng</span>
            {
              patient_id &&
              <Button 
                style={{ height: '100%' }}
                size="small" 
                type="primary"
                onClick={() => {
                  setIsModalOpenCreate(true);
                  setFileUpload([]);
                  if (number === limiter) {
                    setNumber(number - 9);
                  } else {
                    setNumber(number + 1);
                  }
                }}
              >
                Thêm mới
              </Button>
            }
          </>
        }
        bordered
        dataSource={listClinicNote}
        footer={
          <>
            {total > 10 ? (
              <Pagination
                style={{ float: "right" }}
                size="small"
                defaultCurrent={1}
                total={total}
                current={current}
                defaultPageSize={10}
                pageSize={pageSize}
                onChange={(current, pageSize) => {
                  setCurent(current);
                  setPageSize(pageSize);
                }}
              />
            ) : (
              ""
            )}
          </>
        }
        renderItem={(item, index) => (
          <List.Item
            className="item-clinic-note"
            onClick={() => handleClickViewDetail(item?.id)}
            actions={[
              item?.created_at ? (
                <IconDateCreate created_at={item?.created_at} />
              ) : null,
              <IconViewDetail
                onClick={() => handleClickViewDetail(item?.id)}
              />,
            ]}
          >
            <List.Item.Meta
              title={
                <Row>
                  <Col span={16}>
                    <AvatarName
                      avatar={item?.avatar}
                      name={item?.name}
                      profileDoctor={(e) => {
                        e.stopPropagation();
                        setIsModalOpenProfile(true);
                        setDataProfile(item?.doctorArr);
                      }}
                      viewDetail={() => handleClickViewDetail(item?.id)}
                    />
                  </Col>
                  <Col span={7}>
                    {item?.images ? (
                      <IconDocument
                        content={
                          item?.images ? item?.images + " Hình ảnh" : item?.examination_results
                        }
                      />
                    ) : (
                      <IconDocument content={"Không ảnh"} />
                    )}
                  </Col>
                  {/* <Col span={6}>
                    <IconDateCreate created_at={item?.created_at} />
                  </Col> */}
                </Row>
              }
            />
            {/* <div style={{ marginRight: 15 }}>
              <IconDateCreate created_at={item?.created_at} />
            </div> */}
          </List.Item>
        )}
      />
      <Modal
        title="Thêm ghi chú lâm sàng"
        centered
        open={isModalOpenCreate}
        onCancel={() => setIsModalOpenCreate(false)}
        footer={null}
        width={1300}
        className="modal-scroll"
      >
        <ClinicalNotesAdd
          id={patient_id}
          fetchData={fetchClinicNote}
          onSuccess={() => setIsModalOpenCreate(false)}
          setFileUpload={setFileUpload}
          fileUpload={fileUpload}
          number={number}
        />
      </Modal>
      <Modal
        title="Ghi chú lâm sàng chi tiết"
        centered
        open={isModalOpenDetail}
        onCancel={() => SetIsModalOpenDetail(false)}
        footer={null}
        width={900}
        className="modal-scroll"
      >
        <Spin spinning={loading}>
          <ClinicalNotesDetail rPatitent={props} />
        </Spin>
      </Modal>

      <CardProfile
        doctorArr={dataProfile}
        isModalOpenProfile={isModalOpenProfile}
        setIsModalCancelProfile={() => setIsModalOpenProfile(false)}
      />
    </>
  );
}

export default ListClinicNote;
