import {
  BellOutlined,
  CustomerServiceOutlined,
  ExclamationCircleOutlined,
  FileProtectOutlined,
  FlagOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MessageOutlined,
  QrcodeOutlined,
  SettingOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Modal,
  Drawer,
  Tooltip,
  List,
  Form,
  Input,
  message,
  Divider,
  Spin,
  Button,
  Tabs
} from "antd";
import { ReactElement, useEffect, useState, useCallback } from "react";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import itemsMenu from "../menu/index";
import { api } from "src/services";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "src/redux";
// import LogoImed from "../assets/images/logo-imed.png";
import { images } from "src/assets";
import { utils } from "src/utils";
import { DropdownHeader } from "src/components";
import {
  FaBookBookmark,
  FaCapsules,
  FaFileContract,
  FaSignature,
  FaUnlock,
  FaUserXmark,
} from "react-icons/fa6";
import { UserProperty } from "src/types";
import OneSignal from "react-onesignal";
import NotificationItem from "src/components/Notification/NotificationItem";
import Chat from "src/components/Message/Chat";

const { Header, Content } = Layout;

interface Props {
  children: ReactElement | ReactElement[];
}

function Admin({ children }: Props) {
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openChatDrawer, setOpenChatDrawer] = useState(false);
  
  const [loading, setLoading] = useState(false);
  const [userLogin, setUserLogin]: any = useState();
  const [userContract, setUserContract]: any = useState({});
  const [isOpenModalDeleteUser, setIsOpenModalDeleteUser] = useState(false);
  const [form] = Form.useForm();
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const minCurrentScroll = 50;
  
  const [loadingNotification, setLoadingNotification] = useState(false);
  const user = useSelector<Storage, UserProperty>((state) => state.user);

  // New update
  // Khám bệnh Notifications
  const [notifications, setNotifications] = useState([]);
  const [currentScroll, setCurrentScroll] = useState(minCurrentScroll);
  const [hasUnreadNotification, setHasUnreadNotification] = useState(false);
  const [nextCursor, setNextCursor] = useState(null);
  
  // News Notifications
  const [bookingNotifications, setBookingNotifications] = useState([]);
  const [currentNewsScroll, setCurrentNewsScroll] = useState(minCurrentScroll);
  const [hasUnreadNews, sethasUnreadNews] = useState(false);
  const [nextNewsCursor, setNextNewsCursor] = useState(null);
  
  // News Notifications
  const [newsNotifications, setNewsNotifications] = useState([]);
  const [currentChatScroll, setCurrentChatScroll] = useState(minCurrentScroll);
  const [hasUnreadChat, sethasUnreadChat] = useState(false);
  const [nextChatCursor, setNextChatCursor] = useState(null);

  /*  end of init state */
  useEffect(() => {
    OneSignal.init({
      appId: "323e17b0-4ff4-494f-8954-1f6d8ed858eb",
      // serviceWorkerParam: { scope: "/onesignal" },
      // serviceWorkerPath: "/OneSignalSDKWorker.js",
      // serviceWorkerUpdaterPath: "/myPath/OneSignalSDKWorker.js",
      persistNotification: true,
    })
      .then(() => {
        OneSignal.Slidedown.promptPush();
        OneSignal.User.addTag("UserID", user.id.toString());
        OneSignal.Notifications.addEventListener("click", handleClickOneSignal);
        OneSignal.Notifications.addEventListener(
          "foregroundWillDisplay",
          handleForegroundWillDisplayOneSignal
        );

        // OneSignal.Notifications.
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleClickOneSignal = () => {
    fetchNotification();
    fetchBookingNotifications();
    fetchNewsDetailNotifications();
  };

  const handleForegroundWillDisplayOneSignal = () => {
    fetchNotification();
    fetchBookingNotifications();
    fetchNewsDetailNotifications();
  };

  useEffect(() => {
    fetchUserLogin();
  }, []);

  useEffect(() => {
    setHasUnreadNotification(
      notifications.some((item) => !item?.is_read)
      ||  bookingNotifications.some((item) => !item?.is_read)
      ||  newsNotifications.some((item) => !item?.is_read)
    );
  }, [notifications, bookingNotifications, newsNotifications]);

  const fetchBookingNotifications = async () => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getNotifications(
        null,
        currentNewsScroll,
        'booking'
      );
      if (rData && rData.length > 0) {
        setBookingNotifications(rData);
        setNextNewsCursor(meta.next_cursor);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoadingNotification(false);
  };

  const fetchNotification = async () => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getNotifications(
        null,
        currentScroll,
        'notification'
      );
      
      if (rData && rData.length > 0) {
        setNotifications(rData);
        setNextCursor(meta.next_cursor);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoadingNotification(false);
  };

  const fetchNewsDetailNotifications = async () => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getNotifications(
        null,
        currentScroll,
        'news_detail'
      );
      
      if (rData && rData.length > 0) {
        setNewsNotifications(rData);
        setNextChatCursor(meta.next_cursor);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
    setLoadingNotification(false);
  };

  const fetchUserLogin = async () => {
    setLoading(true);
    const { data: rData }: any = await api.getProfile();
    setLoading(false);
    if (rData && rData.userData) {
      setUserLogin(rData.userData);
      if (rData.userData.contract) {
        setUserContract(rData.userData.contract);
      } else {
        setUserContract([]);
      }
    }
  };

  const handleReadNotification = async (item) => {
    const { id, data } = item;
    if (data.screen === "home") {
      setOpenDrawer(false);
      history.push(`/`);
    } else if (data.screen === "booking") {
      setOpenDrawer(false);
      history.push(`/schedules-accepted`);
    } else if (data.screen === "friend_doctor") {
      setOpenDrawer(false);
      history.push(`/doctor-referrers`);
    } else if (data.screen === "friend_patient") {
      setOpenDrawer(false);
      history.push(`/patient-referrers`);
    } else if (data.screen === "news_detail") {
      setOpenDrawer(false);
      history.push(`/news/${data?.params.id}`);
    } else if (data.screen === "chat") {
      setOpenDrawer(false);
      setOpenChatDrawer(true);
    }

    try {
      setLoading(true);
      const response: any = await api.readNotification(id);
      setLoading(false);
      if (response && !response.error) {
        if (data.screen == "booking") {
          fetchNotification();
        } else if  (data.screen == "news_detail"){
          fetchBookingNotifications();
        }else if  (data.screen == "chat"){
          fetchNewsDetailNotifications();
        }
      }
    } catch (err) {
      message.error("Đánh dấu thông báo đã xem thất bại");
      setLoading(false);
    }
  };

  const onSelecteMenu = ({ key }: { key: string }) => {
    if (key === "logout") {
      Modal.confirm({
        okText: "Logout",
        cancelText: "Cancel",
        title: "Do you want to logout?",
        icon: <ExclamationCircleOutlined />,
        onOk() {
          return new Promise((resolve, reject) => {
            api
              .logout()
              .then((res) => {
                localStorage.removeItem("access_token");
                dispatch(allActions.user.deleteUser());
                history.push("/auth/login");
                resolve(res);
              })
              .catch((error) => reject(error));
          });
        },
        onCancel() {
          console.log("Cancel delete");
        },
      });
      return;
    }
    history.push(`${path}${key}`);
  };

  const itemUser = [
    {
      label: (
        <Link to="/personal-information">
          <span>
            <InfoCircleOutlined className="icon-header" />
            Thông tin cá nhân
          </span>
        </Link>
      ),
      // key: "0",
    },
    {
      label: (
        <Link to="/shareqrcode">
          <span>
            <QrcodeOutlined className="icon-header" />
            Qr Code
          </span>
        </Link>
      ),
      // key: "1",
    },
    {
      label: (
        <Link to="/termsandconditions">
          <span>
            <FaBookBookmark className="icon-header" />
            Điều khoản & chính sách
          </span>
        </Link>
      ),
      // key: "2",
    },
    userContract.url && {
      label: (
        <span onClick={() => window.open(userContract.url)}>
          <FaFileContract className="icon-header" />
          Thông tin hợp đồng
        </span>
      ),
      // key: "2",
    },
    {
      label: (
        <Link to="/change-password">
          <span>
            <FaUnlock className="icon-header" />
            Đổi mật khẩu
          </span>
        </Link>
      ),
      // key: "3",
    },
    {
      label: (
        <span onClick={() => onSelecteMenu({ key: "logout" })}>
          <LogoutOutlined className="icon-header" />
          Đăng xuất
        </span>
      ),
      // key: "4",
    },
    {
      label: (
        <span
          onClick={() => showConfirmDeleteAccount()}
          style={{ color: "red" }}
        >
          <FaUserXmark className="icon-header" />
          Xóa tài khoản
        </span>
      ),
      // key: "5",
    },
  ];

  const itemSetting = [
    {
      label: (
        <Link to="/dr/signature">
          <span>
            <FaSignature className="icon-header" />
            Signature Phòng khám
          </span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to="/dr/headLetter">
          <span>
            <FlagOutlined className="icon-header" />
            Head Letter phòng khám
          </span>
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link to="/medicine">
          <span>
            <FaCapsules className="icon-header" />
            Danh mục thuốc
          </span>
        </Link>
      ),
      key: "2",
    },
    {
      label: (
        <Link to="/service">
          <span>
            <CustomerServiceOutlined className="icon-header" />
            Gói dịch vụ
          </span>
        </Link>
      ),
      key: "3",
    },
    {
      label: (
        <Link to="/dr/sample-prescription">
          <span>
            <FileProtectOutlined className="icon-header" />
            Toa thuốc mẫu
          </span>
        </Link>
      ),
      key: "4",
    },
    {
      label: (
        <Link to="/dr/sample-order-test">
          <span>
            <FileProtectOutlined className="icon-header" />
            Yêu cầu xét nghiệm mẫu
          </span>
        </Link>
      ),
      key: "5",
    },
  ];

  const showConfirmDeleteAccount = () => {
    Modal.confirm({
      okText: "Yes",
      cancelText: "Cancel",
      title: "Do you want to delete this account?",
      icon: <UserDeleteOutlined style={{ color: "red" }} />,
      onOk() {
        setIsOpenModalDeleteUser(true);
      },
      onCancel() {
        console.log("Cancel delete");
      },
    });
    return;
  };

  const handleCancel = () => {
    setIsOpenModalDeleteUser(false);
    form.resetFields();
  };

  const handleDeleteAccount = async (fieldValues: any) => {
    try {
      setIsOpenModalDeleteUser(false);
      const response: any = await api.deleteAccount(fieldValues.phone);
      if (!response?.error) {
        message.success(response.message);
        history.push("/auth/login");
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error.message);
      setLoading(false);
    }
  };

  const handleScroll = useCallback(() => {
    setCurrentScroll((prev) => prev + minCurrentScroll);
    setLoadingNotification(true);
  }, []);

  useEffect(() => {
    fetchNotification();
  }, [currentScroll]);

  useEffect(() => {
    fetchBookingNotifications();
  }, [currentNewsScroll]);

  useEffect(() => {
    fetchNewsDetailNotifications();
  }, [currentChatScroll]);

  const loadMore =
    !loading && !loadingNotification && nextCursor != null ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={handleScroll}>Tải thêm</Button>
      </div>
    ) : null;

  const loadNewsMore =
    !loading && !loadingNotification && nextNewsCursor != null ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={handleScroll}>Tải thêm</Button>
      </div>
    ) : null;

  const loadChatMore =
    !loading && !loadingNotification && nextChatCursor != null ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={handleScroll}>Tải thêm</Button>
      </div>
    ) : null;
  return (
    <Layout className="admin">
      <Layout className="site-layout">
        <Header style={{ display: "flex", alignItems: "center" }}>
          <div
            className="logo"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ maxWidth: 100 }}>
              <Link to="/">
                <img
                  alt={images.LogoImedical.logoHome}
                  src={images.LogoImedical.logoHome}
                  style={{ width: "100%" }}
                />
              </Link>
            </div>
          </div>
          <Menu
            onClick={onSelecteMenu}
            theme="dark"
            mode="horizontal"
            selectedKeys={[history.location.pathname.replace(path, "")]}
            items={itemsMenu}
            style={{ display: "flex", justifyContent: "center", width: "80%" }}
          />
          <div className="menu-item" style={{ position: "absolute", right: 0 }}>
            <ul className="top-menu">
              <li className="top-menu-item">
                <DropdownHeader
                  itemsProps={itemSetting}
                  HTMLProps={
                    <div
                      className="avatar-container"
                      style={{ color: "white" }}
                    >
                      <SettingOutlined />
                    </div>
                  }
                />
              </li>
              <li
                className="top-menu-item"
                style={{ borderRight: "1px solid #555", paddingRight: ".5rem" }}
              >
                <Tooltip title="Thông báo">
                  <div
                    className="avatar-container"
                    style={{ color: "#fff", position: "relative" }}
                  >
                    <BellOutlined onClick={() => setOpenDrawer(true)} />
                    {hasUnreadNotification && (
                      <span
                        className="dot"
                        style={{
                          position: "absolute",
                          top: -4,
                          right: -4,
                        }}
                      ></span>
                    )}
                  </div>
                </Tooltip>
              </li>
              <li className="top-menu-item">
                <DropdownHeader
                  itemsProps={itemUser}
                  HTMLProps={
                    <div className="avatar-container">
                      <img
                        className="avatar"
                        src={userLogin?.avatar}
                        alt="User Avatar"
                      />
                      <span style={{ color: "#fff" }}>{userLogin?.name}</span>
                    </div>
                  }
                />
              </li>
            </ul>
            <Drawer
              title="Thông báo"
              width={500}
              onClose={() => setOpenDrawer(false)}
              open={openDrawer}
            >
              <div
              // id="scrollableDiv"
              // // ref={scrollableDivRef}
              // style={{
              //   height: "83vh",
              //   overflow: "auto",
              // }}
              >
                <Tabs
                  defaultActiveKey="1"
                  items={new Array(3).fill(null).map((_, i) => {
                    const id = String(i + 1);
                    if(i === 0){
                      return {
                        label: 'Thông báo',
                        key: id,
                        children: 
                          <NotificationItem
                            notifications={notifications}
                            loadingNotification={loadingNotification}
                            nextCursor={nextCursor}
                            loadMore={loadMore}
                            utils={utils}
                            loading={loading}
                            handleReadNotification={handleReadNotification}
                          />,
                      };
                    }else if(i === 1){
                      return {
                        label: 'Khám bệnh',
                        key: id,
                        children:
                          <NotificationItem
                            notifications={bookingNotifications}
                            loadingNotification={loadingNotification}
                            nextCursor={nextNewsCursor}
                            loadMore={loadMore}
                            utils={utils}
                            loading={loading}
                            handleReadNotification={handleReadNotification}
                          />,
                      };
                    }else if(i === 2){
                      return {
                        label: 'Tin tức',
                        key: id,
                        children:
                          <NotificationItem
                            notifications={newsNotifications}
                            loadingNotification={loadingNotification}
                            nextCursor={nextChatCursor}
                            loadMore={loadMore}
                            utils={utils}
                            loading={loading}
                            handleReadNotification={handleReadNotification}
                          />,
                      };
                    }
                  })}
                />
              </div>
            </Drawer>
          </div>
        </Header>
        <Content>
          <div style={{ margin: "0 8px" }}>
            {children}
          </div>
        </Content>
      </Layout>

      <Chat 
        openDrawer={openChatDrawer} 
        setOpenDrawer={setOpenChatDrawer}
      />

      <Modal
        title="Xóa tài khoản"
        open={isOpenModalDeleteUser}
        onOk={form.submit}
        onCancel={handleCancel}
      >
        <Form form={form} onFinish={handleDeleteAccount} layout="vertical">
          <Form.Item
            label="Vui lòng nhập số điện thoại đã đăng ký để xác nhận thao tác"
            name="phone"
            rules={[
              {
                required: true,
                message: "Số điện thoại không được để trống",
              },
              {
                min: 10,
                max: 11,
                message: "Số điện thoại chưa chính xác",
              },
            ]}
          >
            <Input type="number" placeholder="Nhập số điện thoại" />
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
}

export default Admin;
