import {
  Card,
  Checkbox,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Skeleton,
} from "antd";
import { isEmpty, result } from "lodash";
import { useState } from "react";

function CardTypeTest({
  loading,
  isImageConfirmationNeeded,
  categories,
  selectedValues,
  setSearchQuery,
  messageErrorForTypeText,
  handleCheckboxChange,
  handleSearch,
  loadingOrdertest,
}) {
  const { Panel } = Collapse;
  const [searchInput, setSearchInput] = useState("");

  if (isImageConfirmationNeeded) {
    return null;
  }
  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    const resultSearch = categories.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(searchInput.toLowerCase());
    });
    console.log(resultSearch);
  };
  return (
    <Card
      title="Loại xét nghiệm"
      style={{
        marginBottom: 16,
        height: "unset",
      }}
      size="small"
      loading={loading}
    >
      <Form.Item>
        <Input
          allowClear
          placeholder="Tìm kiếm danh mục"
          onChange={handleSearch}
          // onChange={(e) => searchItems(e.target.value)}
        />
      </Form.Item>

      <Form.Item>
        <Skeleton loading={loadingOrdertest} active>
          <div className="scroll-checkbox">
            <Collapse>
              {categories.map((cat, index) => (
                <Panel header={cat.name} key={index}>
                  <Row>
                    {cat.type_tests.map((test) => (
                      <Col key={test.id} span={24} style={{ marginBottom: 5 }}>
                        <Checkbox
                          checked={selectedValues.includes(test.id)}
                          onChange={() => handleCheckboxChange(test)}
                        >
                          {`${test.name} - ${test.price.toLocaleString(
                            "vi-VN"
                          )} đ`}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Panel>
              ))}
            </Collapse>
          </div>
        </Skeleton>
        <label style={{ color: "red" }}>
          {!isEmpty(messageErrorForTypeText) ? messageErrorForTypeText : ""}{" "}
        </label>
      </Form.Item>
    </Card>
  );
}

export default CardTypeTest;
