import { Button, Card, Col, Form, Input, message, Modal, Radio, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { PersonalInfo } from "src/components";
import { api } from "src/services";
import { contains, utils } from "src/utils";

const CreateAppointmentModal = ({ currentTime, fetchListPatitent }) => {
    const [isModalVisible, setModalVisible]: any = useState(false);
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [patients, setPatients] = useState([]);
    const [clinicSlots, setClinicSlots] = useState([]);
    const [patientInfo, setPatientInfo] = useState(null);
    const [selectedlSlot, setSelectedSlot] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [numberOrder, setNumberOrder] = useState(0);
    const [form] = Form.useForm();
    const [filterPatients, setFilterPatients] = useState([]);
    const [filterServices, setFilterServices] = useState([]);
    const [keywordSearchPatient, setKeywordSearchPatient] = useState('');
    const [keywordSearchService, setKeywordSearchService] = useState('');

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };

    const fetchServices = async () => {
        try {
            setLoading(true);
            const { data: serviceData }: any = await api.getService();
            if (serviceData) {
                const formattedServices = serviceData.map((service: any) => ({
                    key: service.id,
                    ...service,
                }));
                setServices(formattedServices);
                setFilterServices(formattedServices);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchPatient = async (page = 1, pageSize = 10) => {
        try {
            setLoading(true);
            const { data: rPatitent }: any = await api.getMyPatient(contains.patientType, page, '', pageSize);
            if (rPatitent) {
                setPatients(rPatitent);
                setFilterPatients(rPatitent);
            }
        } catch (error) {
            console.log(error);
            message.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchServices();
        fetchPatient();
    }, []);

    const handleSearchPatient = () => {
        const filteredResults = patients.filter((item) => {
            return item.name.toLowerCase().includes(keywordSearchPatient.toLowerCase());
        });
        setFilterPatients(filteredResults);
    }

    const handleSearchService = () => {
        const filteredResults = services.filter((item) => {
            return item.name.toLowerCase().includes(keywordSearchService.toLowerCase());
        });
        setFilterServices(filteredResults); 
    }

    useEffect(() => {
        handleSearchPatient();
    }, [keywordSearchPatient]);

    useEffect(() => {
        handleSearchService();
    }, [keywordSearchService]);

    const handleChangeService = async (service: any) => {
        try {
            setSelectedService(service);
            setLoading(true);
            const { data: rData }: any = await api.getClinicSlot(service?.id, currentTime);
            if (rData) {
                setClinicSlots(rData);
            }
        } catch (error) {
            console.log(error);
            message.error(error);
        } finally {
            setLoading(false);
        }
    }

    const handleChangePatient = async (patient_id: any) => {
        const patientFind = patients.find(item => item.id === patient_id);
        if (patientFind) {
            setPatientInfo(patientFind);
        }
    }

    const handleOnChangeClinicSlot = (slot: any, index: number) => {
        setSelectedSlot(slot);
        setNumberOrder(++index);
    }

    const handleClickSaveClinicSlot = async (values: any) => {
        let valuesForm = {
            address: patientInfo?.address,
            name: patientInfo?.name,
            dob: patientInfo?.dob,
            member_id: patientInfo?.id,
            email: patientInfo?.email,
            gender: patientInfo?.gender,
            phone: patientInfo?.phone,
            payment_confirm: 'waiting_accept',
            payment_method: values?.payment_method,
            price: selectedService?.price,
            service_id: values?.service_id,
            slot_date: currentTime,
            slot_start_time: selectedlSlot?.start_time,
            slot_end_time: selectedlSlot?.end_time,
            slot_id: selectedlSlot?.id,
            status: 'accept'
        }

        try {
            setLoading(true);
            const response: any = await api.createBookingByDoctor(valuesForm);
            if (response.error) {
                message.error(response.message);
            } else {
                message.success(response.message);
                form.resetFields();
                fetchListPatitent();
                handleCancel();
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <Button style={{ height: '100%' }} size="small" type="primary" onClick={showModal}>
                Tạo hẹn lịch tái khám
            </Button>
            <Modal
                title="Tạo lịch hẹn tái khám"
                open={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={1400}
                centered
                className="modal-create-appointment"
            >
                <Spin tip="Loading..." spinning={loading} >
                    <Form
                        form={form}
                        name="validate_other"
                        onFinish={handleClickSaveClinicSlot}
                        initialValues={{ payment_method: 'cash' }}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={14}>
                                <Card
                                    className="card-order-left cart-appoiment-left"
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div className="order-content order-content_wapper package_test_wrapper">
                                        <div className="packet_test">
                                            {patients.length > 0 && (
                                                <>
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <strong>Bệnh nhân: </strong>
                                                    </div>
                                                    <Form.Item>
                                                        <Input
                                                            allowClear
                                                            placeholder="Tìm kiếm bệnh nhân"
                                                            onChange={(e) => setKeywordSearchPatient(e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="patient_id"
                                                        rules={[{ required: true, message: 'Vui lòng chọn bệnh nhân!' }]}
                                                    >
                                                        <Radio.Group
                                                            buttonStyle="solid"
                                                        >
                                                            {filterPatients.map((item) => (
                                                                <Radio.Button
                                                                    key={item.id}
                                                                    value={item.id}
                                                                    style={{
                                                                        marginBottom: ".3rem",
                                                                        width: "100%",
                                                                    }}
                                                                    onChange={() => handleChangePatient(item?.id)}
                                                                >
                                                                    {item.name}
                                                                </Radio.Button>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </div>
                                        <div className="location_test">
                                            {services.length > 0 && (
                                                <>
                                                    <div style={{ marginBottom: '10px' }}>
                                                        <strong>Gói dịch vụ: </strong>
                                                    </div>
                                                    <Form.Item>
                                                        <Input
                                                            allowClear
                                                            placeholder="Tìm kiếm dịch vụ"
                                                            onChange={(e) => setKeywordSearchService(e.target.value)}
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="service_id"
                                                        rules={[{ required: true, message: 'Vui lòng chọn gói dịch vụ!' }]}
                                                    >
                                                        <Radio.Group
                                                            buttonStyle="solid"
                                                        >
                                                            {filterServices.map((item) => (
                                                                <Radio.Button
                                                                    key={item.id}
                                                                    value={item.id}
                                                                    style={{
                                                                        marginBottom: ".3rem",
                                                                        width: "100%",
                                                                    }}
                                                                    onChange={() => handleChangeService(item)}
                                                                >
                                                                    {item.name} - {utils.formatMoney(item?.price)}đ
                                                                </Radio.Button>
                                                            ))}
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Card>

                                {patientInfo &&
                                    <Card
                                        className="card-order-left cart-appoiment-patient-detail"
                                        style={{
                                            marginBottom: 16,
                                            height: "unset",
                                        }}
                                    >
                                        <PersonalInfo patientInfo={patientInfo} />
                                    </Card>
                                }

                                <Card
                                    className="card-order-left cart-appoiment-top"
                                >
                                    <Button type="primary" loading={loading} htmlType="submit">
                                        Lưu
                                    </Button>
                                </Card>
                            </Col>
                            <Col span={10}>
                                <Card
                                    className="card-order-left cart-appoiment-clinic-slot cart-appoiment-right"
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div>
                                        <strong>Thời gian khám: </strong>{utils.formatDay(currentTime)}
                                    </div>
                                    <div style={{ marginBottom: '10px' }}>
                                        <strong>Số thứ tự khám: </strong>0{numberOrder} (dự kiến)
                                    </div>
                                    <Form.Item
                                        name="clinic_slot_id"
                                        rules={[{ required: true, message: 'Vui lòng chọn thời gian khám!' }]}
                                    >
                                        {clinicSlots && clinicSlots.length > 0 && (
                                            <Radio.Group buttonStyle="solid">
                                                {clinicSlots.map((item, index) =>
                                                    <Radio.Button key={item?.id} value={item?.id} onChange={() => handleOnChangeClinicSlot(item, index)}>
                                                        {item?.start_time} - {item?.end_time}
                                                    </Radio.Button>
                                                )}
                                            </Radio.Group>
                                        )}
                                    </Form.Item>
                                </Card>

                                <Card
                                    className="card-order-left cart-appoiment-clinic-slot"
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                >
                                    <div style={{ marginBottom: '10px' }}>
                                        <strong>Phương thức thanh toán: </strong>
                                    </div>
                                    <Form.Item
                                        name="payment_method"
                                    >
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button key='banking' value='banking'>
                                                Thanh toán chuyển khoản
                                            </Radio.Button>
                                            <Radio.Button key='cash' value='cash'>
                                                Thanh toán tại phòng khám
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </>
    );
};

export default CreateAppointmentModal;
