import { Image, Modal, Table } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconViewDetail } from "src/components";
import { api } from "src/services";
function OrderTestByPatient() {
  const { id }: any = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalDetail, setModalDetail] = useState(false);
  const [imageOrderTest, setImageOrderTest] = useState([]);
  const [filePDF, setFilePDF]: any = useState([]);
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Gói xét nhận",
      dataIndex: "packet",
      key: "packet",
      width: 200,
    },
    {
      title: "Lấy xét nghiệm tại",
      dataIndex: "sampling_packages",
      key: "sampling_packages",
      width: 200,
    },
    {
      title: "Ngày khám",
      dataIndex: "day_format",
      key: "day_format",
      width: 150,
    },
    {
      title: "Tổng tiền",
      dataIndex: "total",
      key: "total",
      width: 100,
    },
    {
      title: "Hành động",
      width: 100,
      render: (text: string, record: any) => (
        <IconViewDetail onClick={() => handleClickViewDetail(record)} />
      ),
    },
  ];

  const handleClickViewDetail = (record) => {
    setModalDetail(true);
    setImageOrderTest(record.images || []);
    setFilePDF(record.file_pdf || null);
    // Set images or an empty array
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    fetchData(pagination.current, pagination.pageSize);
  };

  const fetchData = async (page?: 1, pageSize?: 20) => {
    try {
      setLoading(true);
      const { data: rData, meta }: any = await api.getAllTestByIdPatient(
        id,
        page,
        pageSize
      );
      if (rData) {
        setData(rData);
        setTableParams({
          ...tableParams,
          pagination: {
            current: page,
            pageSize: meta.per_page,
            total: meta.total,
          },
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="admin-table">
      <div className="flex-fill " style={{ marginTop: "3rem" }}>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Phiếu yêu cầu xét nghiệm"
        open={modalDetail}
        footer={null}
        onCancel={() => setModalDetail(false)}
        width={1000}
        centered
      >
        {imageOrderTest.length > 0 ? (
          imageOrderTest.map((imageUrl, index) => (
            <Image key={index} width={200} src={imageUrl} />
          ))
        ) : filePDF ? (
          <embed
            src={filePDF}
            width="100%"
            height="500px"
            type="application/pdf"
          />
        ) : (
          <p>Không thấy thông tin</p>
        )}
      </Modal>
    </div>
  );
}
export default OrderTestByPatient;
