import network from "../network/network";

type NetworkPromiseResponse<T> = Promise<T>;

function getAllDoctorReferrers<T>(
  per_page: number,
  page: number,
  member_type_id: number,
  q: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/friends?per_page=${per_page}&page=${page}&member_type_id=${member_type_id}&q=${q}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDoctorReffers<T>(
  per_page: number,
  page: number,
  search: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/doctor-referrers?q=${search}&per_page=${per_page}&page=${page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function createDoctorReffers<T>(
  doctor_id: number,
  patient_id: number,
  note: string
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(
        `v1/doctor-referrers/create?doctor_id=${doctor_id}&patient_id=${patient_id}&note=${note}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllPatientReferrers<T>(
  per_page: number,
  page: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/doctor-referrers/patients?per_page=${per_page}&page=${page}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function deletePatientReferrer<T>(
  id: number,
  referrer_id: number
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .delete(`v1/doctor-referrers/${id}?referrer_id=${referrer_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function patientInfoRegister<T>(formData: any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
    .post(`v1/patient-info-register`, formData)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDoctorReffersById<T>(
  patient_id: number,
  page: number,
  per_page: number,
  seach: any
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(
        `v1/doctor-referrers?per_page=${per_page}&page=${page}&patient_id=${patient_id}&q=${seach}`
      )
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDoctorsReferrersByUser<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/get-doctor-referrers-by-doctor`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getAllProvinces<T>(): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/get-provinces`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getDistrictsByProvince<T>(
  province_id: number,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/get-districts-by-province/${province_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function getWardsByDistrict<T>(
  district_id: number,
): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .get(`v1/get-wards-by-district/${district_id}`)
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}



function addFreind<T>(friend_id : any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/friends/create`, { friend_id })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

function addFreindReference<T>(doctor_id : any, patient_id : any, note:any): NetworkPromiseResponse<T> {
  return new Promise((resolve, reject) => {
    network
      .post(`v1/doctor-referrers/create`, { doctor_id, patient_id, note })
      .then((res: any) => {
        if (res.status >= 400) {
          reject(res);
        }
        resolve(res);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
}

const data = {
  getAllDoctorReferrers,
  getDoctorReffers,
  createDoctorReffers,
  getAllPatientReferrers,
  deletePatientReferrer,
  getDoctorReffersById,
  getDoctorsReferrersByUser,
  patientInfoRegister,
  getAllProvinces,
  getDistrictsByProvince,
  getWardsByDistrict,
  addFreind,
  addFreindReference
};

export default data;
