import React, { useEffect, useState } from "react";
import { Card, List } from 'antd';
import { NavLink } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { api } from "src/services";

const NewsList = () => {

  const [news_data, setNewsData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchNewsPost = async () =>{
    try {
      setLoading(true);
      const { data }:any = await api.getNewsPost();
      if (data && data.length > 0) {
        setNewsData(data);
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false);
  }
  /* EFFECT CHANGE RENDER */

  useEffect(()=>{
    fetchNewsPost();
  },[])

  return (
    <div className="news">
      <h1>Tin tức mới</h1>
      <List
        className="ant-news-list"
        dataSource={news_data}
        loading={loading}
        renderItem={(item:any, index:any)=>(
          <NavLink to={`/news/${item.id}`} key={index}>
            <Card key={index} cover={<img alt={item.name} src={item.image} />}>
              <div className="fs-18 IBMPlexSans-Bold">{item.name}</div>
            </Card>
          </NavLink>
        )}
      /> 
      <div className="news-link">
        <NavLink to="/news">
          Xem tất cả
          <ArrowRightOutlined />
        </NavLink>
      </div>
    </div>
  );
};
export default NewsList;
