import { PlusOutlined } from "@ant-design/icons";
import { Modal, Button, Image, Upload, message } from "antd";
import { useState } from "react";
import { api } from "src/services";

const RefundConfirmationModal = ({
  isVisible,
  handleCancel,
  booking,
  fetchListPatitent
}) => {
  const [fileUpload, setFileUpload] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFinishForm = async () => {
    if (!fileUpload.length) {
      return message.error("Vui lòng chọn hình ảnh/biên lai đã hoàn tiền cho bệnh nhân!");
    }

    let formData: any = new FormData();
    formData.append('payment_confirm', 'refunded');
    fileUpload.forEach(file => {
      formData.append('images[]', file.file);
    });

    try {
      setLoading(true);
      const response: any = await api.updateBookingPayment(booking?.id, formData);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success('Xác nhận hoàn tiền thành công!');
      }
    } catch (error) {
      message.error(error.message);
      console.log(error);
    } finally {
      setFileUpload([]);
      fetchListPatitent();
      setLoading(false);
      handleCancel();
    }
  };

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const onBeforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Bạn chỉ có thể upload file JPG/PNG!");
      setFileUpload([]);
      return;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Ảnh phải có dung lượng nhỏ hơn 2MB!");
      return false;
    }

    const fileObject = {
      name: file.name,
      size: file.size,
      url: null,
      file: file,
    };
    setFileUpload((prevFiles) => prevFiles.concat(fileObject));

    getBase64(file, (url: any) => {
      fileObject.url = url;
    });

    return false;
  };

  const onRemove = (file: any) => {
    const updatedFiles = fileUpload.filter((item) => item.name !== file.name);
    setFileUpload(updatedFiles);
  };

  return (
    <Modal
      title="Xác nhận đã hoàn tiền"
      open={isVisible}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="text-semibold mb-1">Hình ảnh/biên lai xác nhận thanh toán của bệnh nhân</div>
      {
        booking?.images_payment_member?.length ?
          <div className="d-flex gap-1 flex-wrap">
            <Image.PreviewGroup>
              {booking?.images_payment_member?.map((item: any, index: number) => (
                <Image style={{ objectFit: 'cover' }} width={100} height={100} src={item} key={index} />
              ))
              }
            </Image.PreviewGroup>
          </div>
          :
          <div>Không có hình ảnh biên lai xác nhận thanh toán nào</div>
      }

      <div className="text-semibold mt-1 mb-1">Tải hình ảnh/biên lai xác nhận đã hoàn tiền</div>
      <Upload
        listType="picture-card"
        showUploadList={true}
        beforeUpload={onBeforeUpload}
        multiple={true}
        onRemove={onRemove}
        accept=".jpg, .jpeg, .png"
        fileList={fileUpload}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 4 }}>Upload</div>
        </div>
      </Upload>

      <div className="text-center mt-1">
        <Button type="primary" loading={loading} onClick={handleFinishForm}>
          Xác nhận đã hoàn tiền
        </Button>
      </div>
    </Modal>
  );
};

export default RefundConfirmationModal;
