import { Form, Modal, Table, message } from "antd";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  IconEdit,
  IconDelete,
  BreadcrumbComponent,
  OnDeleteButton,
  AddButton,
  SearchInput,
  CreateServiceModal,
  DeleteConfirmModal,
  EditServiceModal,
  IconSetting,
} from "src/components";

function List() {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalCreate, setIsModalCreate] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);
  const [isModalUpdate, setIsModalUpdate] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const form = Form.useForm()[0];
  const formDelete = Form.useForm()[0];
  const history = useHistory();

  const { path } = useRouteMatch();

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
  });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };
  const fetchServices = async () => {
    try {
      setLoading(true);
      const { data: serviceData }: any = await api.getService();
      if (serviceData) {
        const formattedServices = serviceData.map((service: any) => ({
          key: service.id,
          ...service,
        }));
        setServices(formattedServices);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    values.price = parseInt(values.price.replace(/,/g, ""));
    if (values.price < 0) {
      return message.error("Số tiền nhập không được là số âm");
    }
    try {
      setLoading(true);
      const response: any = await api.createService(values);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      fetchServices();
      handleCancel();
    }
  };

  const handleDelete = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.deleteService(values.id);
      if (response.error) {
        message.error(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error);
    } finally {
      setLoading(false);
      fetchServices();
      handleCancel();
    }
  };

  const handleUpdate = async (values) => {
    try {
      setLoading(true);
      const response: any = await api.updateService(values.id, values);

      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      fetchServices();
      handleCancel();
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRows);
      setSelectedRowKeys(selectedRows.map((el) => el?.id));
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRowKeys(selectedRows.map((el) => el.id));
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRowKeys(selectedRows.map((el) => el.id));
    },
  };

  const onConfirmDelete = () => {
    Modal.confirm({
      okText: "Delete",
      cancelText: "Cancel",
      title: "Bạn có chắc chắn muốn xóa không?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        try {
          setLoading(true);
          const response: any = await api.deleteAllService(selectedRowKeys);
          // const response: any = await Promise.all(
          //   selectedRowKeys.map((id) => api.deleteService(parseInt(`${id}`)))
          // );
          // if (response.error) {
          //   message.error(response.message);
          // } else {
          //   console.log(response[0].message);
          //   message.success(response[0].message);
          // }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
          fetchServices();
          setSelectedRowKeys([]);
        }
      },
    });
  };

  const handleCancel = () => {
    setIsModalCreate(false);
    setIsModalDelete(false);
    setIsModalUpdate(false);
  };

  useEffect(() => {
    setServices([]);
    fetchServices();
  }, []);

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Tên dịch vụ",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return (
          <div
            className="hover-cuser"
            onClick={() => {
              history.push(`${path}/setting/${record.id}`);
            }}
          >
            {" "}
            {text}
          </div>
        );
      },
    },
    {
      title: "Giá",
      dataIndex: "price",
      key: "price",
      render: (text) => {
        return `${Number(text).toLocaleString("vi-VN")} đ`;
      },
    },
    {
      title: "Hành động",
      render: (text: string, record: any) => (
        <>
          <IconEdit
            onClick={() => {
              setIsModalUpdate(true);
              form.setFieldsValue({
                id: record.id,
                name: record.name,
                price: record.price,
              });
            }}
          />
          <IconDelete
            onClick={() => {
              setIsModalDelete(true);
              formDelete.setFieldsValue({
                id: record.id,
              });
            }}
          />
          <IconSetting
            onClick={() => {
              history.push(`${path}/setting/${record.id}`);
            }}
          />
        </>
      ),
    },
  ];

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/service", name: "Gói dịch vụ" },
  ];

  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        >
          <div>
            <OnDeleteButton
              onClick={onConfirmDelete}
              disabled={selectedRowKeys.length === 0}
              title={"Xóa"}
            />
            <SearchInput
              onSearch={(value) => {
                setSearchQuery(value);
              }}
              title={"Tìm kiếm"}
              width={250}
            />
          </div>
          <AddButton
            onClick={() => {
              setIsModalCreate(true);
              form.resetFields();
            }}
            title={"Thêm mới"}
          />
        </div>
        <Table
          rowSelection={{ ...rowSelection }}
          columns={columns}
          dataSource={services.filter((category) =>
            category.name.toLowerCase().includes(searchQuery.toLowerCase())
          )}
          loading={loading}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
        <CreateServiceModal
          open={isModalCreate}
          onCancel={handleCancel}
          loading={loading}
          handleSubmit={handleSubmit}
          form={form}
        />
        <DeleteConfirmModal
          open={isModalDelete}
          onCancel={() => setIsModalDelete(false)}
          loading={loading}
          handleSubmit={handleDelete}
          form={formDelete}
        />
        <EditServiceModal
          open={isModalUpdate}
          onCancel={() => setIsModalUpdate(false)}
          loading={loading}
          handleSubmit={handleUpdate}
          form={form}
        />
      </div>
    </div>
  );
}

export default List;
