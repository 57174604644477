import {
  Checkbox,
  DatePicker,
  Form,
  InputNumber,
  Select,
  Space,
  Table,
  message,
} from "antd";
import { useParams } from "react-router-dom";
import { MedicalExaminationSlot, StoreState } from "src/types";
import { useEffect, useState } from "react";
import { api } from "src/services";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  AddButton,
  BreadcrumbComponent,
  CreateScheduleModal,
  CustomCalendar,
  DeleteScheduleModal,
  EditScheduleModal,
  FormatDate,
  IconDelete,
  IconEdit,
} from "src/components";
import { FaCircleCheck, FaD, FaW } from "react-icons/fa6";
import locale from "antd/lib/date-picker/locale/vi_VN";

export default function SettingSchedule() {
  const { id }: any = useParams();
  const [data, setData] = useState([]);
  const [clinicSlot, setClinicSlot] = useState([]);
  const deleteForm = Form.useForm()[0];
  const updateForm = Form.useForm()[0];
  const [loading, setLoading] = useState(false);
  const [isUpdateModal, setUpdateModal] = useState(false);
  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [bookingBadge, setBookingBadge]: any = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment().format("YYYY-MM-DD"));
  const [recordStates, setRecordStates] = useState({});
  const setting = useSelector<StoreState, Object>(
    (state: any) => state.setting
  );

  const initialSlotValues = {
    id: "",
    date: "",
    patient_quantity: "",
    repeat: "onlyday",
  };
  const deleteFormInitialValues = {
    id: "",
    date: "",
    repeat: "onlyday",
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 20,
    },
  });

  const [tableCreateParams, setTableCreateParams] = useState({
    pagination: {
      current: 1,
      pageSize: 13,
    },
  });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleTableCreateChange = (
    pagination: any,
    filters: any,
    sorter: any
  ) => {
    setTableCreateParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const fetchClinicSlotAndData = async () => {
    try {
      setLoading(true);
      const { data: rData }: { data: any } = await api.getClinicSlot(
        id,
        currentTime
      );
      if (rData) {
        setClinicSlot(rData);
        let newData = calculateTimes();
        for (const item of rData) {
          const index = newData.findIndex(
            (el) => el.id === `${item.start_time}-${item.end_time}`
          );
          if (index !== -1) {
            newData = [
              ...newData.slice(0, index),
              {
                end_date: moment(item.end_date).format("YYYY-MM-DD"),
                end_time: item.end_time,
                id: item.id,
                patient_quantity: item.patient_quantity,
                start_time: item.start_time,
                repeat: "weekly",
              },
              ...newData.slice(index + 1),
            ];
          }
        }
        let dataArr = newData.map((el: any, index: number) => ({
          key: index,
          ...el,
        }));
        setData(dataArr);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTimes = () => {
    let times = [];
    let startTime = moment(
      setting["working_hour"].start ? setting["working_hour"].start : "6:00",
      "HH:mm"
    );
    let endTime = moment(
      setting["working_hour"].end ? setting["working_hour"].end : "21:00",
      "HH:mm"
    );

    while (startTime < endTime) {
      let item: MedicalExaminationSlot | any = {};
      item.start_time = moment(startTime).format("HH:mm");
      startTime.add(
        setting["working_hour"].interval_time
          ? setting["working_hour"].interval_time
          : 15,
        "m"
      );
      item.end_time = moment(startTime).format("HH:mm");
      item.id = `${item.start_time}-${item.end_time}`;
      item.patient_quantity = 0;
      item.end_date = null;
      times.push(item);
    }
    return times;
  };

  const onChangeDate = (date, recordKey) => {
    if (!date || !date.isValid()) {
      date = moment().add("days", 1);
    }
    const updatedData = data.map((record) =>
      record.key === recordKey
        ? { ...record, end_date: date?.format("YYYY-MM-DD") }
        : record
    );
    setData(updatedData);
  };

  const changeStatus = (record) => {
    const updatedData = data.map((el) =>
      el.key === record.key
        ? {
            ...el,
            patient_quantity: el.patient_quantity === 0 ? 1 : 0,
            end_date: calculateEndDate(),
            repeat: "weekly",
          }
        : el
    );
    setData(updatedData);
  };

  const calculateEndDate = () => {
    const currentDate = new Date();
    const oneYearLater = new Date(currentDate);
    oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
    oneYearLater.setHours(0, 0, 0, 0);
    return oneYearLater.toISOString().split("T")[0];
  };

  const handleQuantity = (record, newQuantity) => {
    const updatedData = data.map((el) =>
      el.key === record.key ? { ...el, patient_quantity: newQuantity } : el
    );
    setData(updatedData);
  };

  const handleSelect = (value, record) => {
    const updatedData = data.map((el) =>
      el.key === record.key ? { ...el, repeat: value } : el
    );
    setData(updatedData);
  };

  const handleCancel = () => {
    setDeleteModal(false);
    setUpdateModal(false);
    setCreateModal(false);
  };

  const handleSubmit = async () => {
    const dataFormat = data.filter((slot) => slot.patient_quantity > 0);
    const formData = {
      date: currentTime,
      service_id: id,
      slots: dataFormat,
    };
    try {
      setLoading(true);
      const response: any = await api.storeClinicSlot(formData);
      // if (response.error) {
      //   message.error(response?.message);
      // } else {
      //   message.success(response?.message);
      // }
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    } finally {
      fetchClinicSlotAndData();
      setLoading(false);
      handleCancel();
    }
  };

  const handleDelete = async (values) => {
    try {
      setIsDeleting(true);
      const response: any = await api.deleteClinicSlot(values.id, values);
      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    } finally {
      fetchClinicSlotAndData();
      handleCancel();
      setIsDeleting(false);
    }
  };

  const fetchBookingBadge = async () => {
    try {
      setLoading(true);
      const startDate = moment(currentTime)
        .startOf("month")
        .format("YYYY-MM-DD");
      const endDate = moment(currentTime).endOf("month").format("YYYY-MM-DD");
      const { data: rData }: any = await api.getBookingBadge(
        startDate,
        endDate
      );
      if (rData) {
        setBookingBadge(rData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Số người khám",
      dataIndex: "patient_quantity",
      key: "patient_quantity",
      width: 100,
      render: (text, record) => <div>{text} Bệnh nhân</div>,
    },
    {
      title: "Giờ bắt đầu",
      dataIndex: "start_time",
      key: "start_time",
      width: 100,
      render: (text, record) => <div>{record.start_time}</div>,
    },
    {
      title: "Giờ kết thúc",
      dataIndex: "end_time",
      key: "dob",
      width: 100,

      render: (text, record) => <div>{record.end_time}</div>,
    },
    {
      title: "Ngày kết thúc",
      dataIndex: "end_date",
      key: "end_date",
      width: 100,
      render: (text, record) => (
        <div>{<FormatDate date={record.end_date} />}</div>
      ),
    },
    {
      title: "Số người khám",
      dataIndex: "patient_quantity",
      key: "patient_quantity",
      width: 150,
      render: (text, record) => (
        <div key={record.id}>
          <InputNumber
            style={{ marginRight: "0.5rem" }}
            width={10}
            min={1}
            defaultValue={record.patient_quantity}
            onChange={(value) => handleInputChange(record.id, value)}
          />
          {recordStates[record.id] === "daily" ? (
            <FaD
              className="hover-cuser"
              onClick={() => handleUpdateRecord(record.id)}
            />
          ) : (
            <FaW
              className="hover-cuser"
              onClick={() => handleUpdateRecord(record.id)}
            />
          )}
        </div>
      ),
    },
    {
      title: "Hành động",
      width: 100,
      key: "action",
      render: (text: string, record: any) => (
        <div>
          <IconEdit
            onClick={() => {
              setUpdateModal(true);
              updateForm.resetFields();
              updateForm.setFieldsValue({
                id: record.id,
                patient_quantity: record.patient_quantity,
                date: currentTime,
              });
            }}
          />
          <IconDelete
            onClick={() => {
              setDeleteModal(true);
              deleteForm.resetFields();
              deleteForm.setFieldsValue({
                id: record.id,
                date: currentTime,
              });
            }}
          />
          <span className="icon-container">
            <FaCircleCheck
              onClick={() => {
                handleUpdate(record);
              }}
              title={"Chỉnh sửa"}
              style={{ color: "#52c41a  ", cursor: "pointer" }}
            />
          </span>
        </div>
      ),
    },
  ];

  const [updateQuantity, setUpdateQuantity] = useState([]);

  const handleUpdateRecord = (recordId) => {
    setRecordStates((prevStates) => ({
      ...prevStates,
      [recordId]: prevStates[recordId] === "daily" ? "weekly" : "daily",
    }));
  };

  const handleInputChange = (recordId, value) => {
    setUpdateQuantity((prevValues) => ({
      ...prevValues,
      [recordId]: value,
    }));
  };

  const handleUpdate = async (values) => {
    values.date = values.end_date;
    const recordId = values.id;
    let valueRepeatNew = recordStates[recordId];
    let valueQuantityNew = updateQuantity[recordId];
    values.date = new Date(values.date).toISOString().slice(0, 10);

    valueRepeatNew = recordStates[values.id] === "daily" ? "daily" : "weekly";
    if (!valueRepeatNew) {
      valueRepeatNew = "weekly";
    }
    if (valueQuantityNew) {
      if (values.patient_quantity === null) {
        valueQuantityNew = 1;
      }
    } else {
      valueQuantityNew = values.patient_quantity;
    }

    try {
      setIsUpdating(true);
      const valueUpdate = {
        id: values.id,
        date: values.date,
        repeat: valueRepeatNew,
        patient_quantity: valueQuantityNew,
      };
      console.log(valueUpdate);

      const response: any = await api.updateClinicSlot(values.id, valueUpdate);

      if (response.error) {
        message.error(response.message);
      } else {
        message.success(response.message);
      }
    } catch (error) {
      console.log(error);
      message.error(error?.message);
    } finally {
      setRecordStates("daily");
      setIsUpdating(false);
      fetchClinicSlotAndData();
      handleCancel();
    }
  };

  const columnSlot = [
    {
      title: "Thời gian",
      dataIndex: "index",
      key: "index",
      width: 100,
      render: (text: string, record: any) => (
        <Space size="middle">
          <Checkbox
            checked={record.patient_quantity > 0}
            onChange={() => changeStatus(record)}
          >
            {record.start_time} - {record.end_time}
          </Checkbox>
        </Space>
      ),
    },
    {
      title: "Lặp",
      dataIndex: "repeat",
      key: "repeat",
      width: 100,
      render: (text, record) => (
        <Space size="middle">
          <Select
            style={{ width: 120 }}
            defaultValue={"weekly"}
            options={[
              { value: "weekly", label: "weekly" },
              { value: "daily", label: "daily" },
            ]}
            onChange={(value) => handleSelect(value, record)}
            disabled={record.patient_quantity < 1}
          />
        </Space>
      ),
    },
    {
      title: "Số lượng khám",
      dataIndex: "patient_quantity",
      key: "patient_quantity",
      width: 100,
      render: (text: string, record: any) => (
        <Space size="middle">
          <InputNumber
            width={50}
            size="large"
            min={1}
            max={100000}
            value={record.patient_quantity}
            onChange={(newQuantity) => handleQuantity(record, newQuantity)}
            disabled={record.patient_quantity < 1}
          />
        </Space>
      ),
    },
    {
      title: "Đến Ngày",
      dataIndex: "end_date",
      key: "end_date",
      width: 100,
      render: (text, record) => (
        <div>
          <DatePicker
            locale={locale}
            value={moment(record.end_date, "YYYY-MM-DD")}
            onChange={(date) => onChangeDate(date, record.key)}
            disabled={record.patient_quantity < 1}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchClinicSlotAndData();
    fetchBookingBadge();
  }, [currentTime]);

  const breadcrumbItems = [
    { path: "/", name: "Dashboard" },
    { path: "/service", name: "Gói dịch vụ" },
    { path: "#", name: "Cài đặt lịch khám " },
  ];

  return (
    <div className="admin-table">
      <div>
        <BreadcrumbComponent items={breadcrumbItems} />
      </div>
      <div className="flex-fill">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: ".5rem",
          }}
        >
          <div></div>
          <AddButton
            onClick={() => {
              setCreateModal(true);
            }}
            title={"Tạo lịch khám"}
          />
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: 500 }}>
            <CustomCalendar
              dateToMark={bookingBadge}
              setCurrentTime={setCurrentTime}
            />
          </div>

          <Table
            columns={columns}
            dataSource={clinicSlot}
            loading={loading}
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            style={{ width: "70%", marginLeft: ".5rem" }}
          />
        </div>
      </div>
      <CreateScheduleModal
        open={isCreateModal}
        onCancel={handleCancel}
        loading={loading}
        handleSubmit={handleSubmit}
        columnSlot={columnSlot}
        data={data}
        tableCreateParams={tableCreateParams}
        handleTableCreateChange={handleTableCreateChange}
      />
      <EditScheduleModal
        open={isUpdateModal}
        onCancel={handleCancel}
        onFinish={handleUpdate}
        form={updateForm}
        initialValues={initialSlotValues}
        isUpdating={isUpdating}
      />
      <DeleteScheduleModal
        open={isDeleteModal}
        onCancel={handleCancel}
        onFinish={handleDelete}
        form={deleteForm}
        initialValues={deleteFormInitialValues}
        isDeleting={isDeleting}
      />
    </div>
  );
}
