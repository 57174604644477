import { Form, Select, Input, Button, message } from "antd";
import { useEffect, useRef, useState } from "react";
import {} from "react-router-dom";
import {} from "@ant-design/icons";
import { api } from "src/services";
import { contains } from "src/utils";
import {} from "src/components";
export default function DoctorReferrersModal({ handleSubmit }) {
  const [listPatients, setListPatients] = useState([]);
  const [listDoctors, setListDoctors] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const formRef = useRef<any>(null);
  useEffect(() => {
    fetchPatient();
    fetchDoctorFreind();
  }, []);
  const fetchPatient = async () => {
    const { data: rPatitent }: any = await api.getMyPatient(
      contains.patientType,
      1,
      searchValue,
      10000
    );
    if (rPatitent) {
      let patientArr = rPatitent.map((el: any) => ({ key: el?.id, ...el }));
      setListPatients(patientArr);
    }
  };
  const fetchDoctorFreind = async () => {
    const { data: rDoctors }: any = await api.getDoctorFriendApi(
      contains.doctorType,
      1,
      searchValue,
      10000
    );
    if (rDoctors) {
      let doctorArr = rDoctors.map((el: any) => ({ key: el?.id, ...el }));
      setListDoctors(doctorArr);
    }
  };
  // const handleSubmit = async (values: any) => {
  //   let formData = new FormData();
  //   formData.append("doctor_id", values.doctor_id);
  //   formData.append("patient_id", values.patient_id);
  //   formData.append("note", values.note);
  //   console.log(values);
  //   try {
  //     setLoading(true);
  //     const response: any = await api.createDoctorReferrers(formData);
  //     if (response.error) {
  //       message.error(response.message);
  //     } else {
  //       message.success(response.message);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //     setIsModalCancelDoctorRefer();
  //   }
  // };
  return (
    <div className="doctor-referrers-detail">
      <Form
        ref={formRef}
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        className="form"
      >
        <div className="doctor-friend">
          <Form.Item name="doctor_id">
            <Select
              showSearch={true}
              style={{ width: "100%" }}
              placeholder="Chọn bác sĩ giới thiệu"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={listDoctors.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </div>
        <div className="patient">
          <Form.Item name="patient_id">
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Chọn bệnh nhân giới thiệu"
              filterOption={(input: any, option: any) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={listPatients.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </div>
        <div className="description">
          <Form.Item label="Ghi chú" name="note">
            <Input />
          </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
